// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// `.env.ts` is generated by the `npm run env` command
import { Config } from 'timeghost-api';
import git from '../../git.json';
import { env } from './.env';
import baseEnvironment from './baseEnvironment';
const serverUrl = env.AZ_FUNC_ENDPOINT || 'https://timeghost-dev.azurewebsites.net/api';
export const environment = {
  ...baseEnvironment,
  isdev: true,
  chargebeeSite: 'timeghost-test',
  version: env.npm_package_version + '-dev#' + git.hash,
  hash: git.hash,
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'de-DE', 'es-ES', 'pt-BR', 'fr-FR', 'nl-NL', 'da-DA'],
  production: false,
  enableSentry: false,
  clientUrl: 'https://web-dev.timeghost.io',
  baseUrl: 'https://web-beta.timeghost.io',
  serverUrl,
  //serverUrl: 'http://localhost:7071/api',
  azureFunctionsKey: 'FpKJbpy5NuSYnY1v9Ar4cKwi2lTPFtoUanXtnkoJZY8yQhpefgHfCQ==',
  adalConfig: {
    clientId: '57b3475c-f720-4b10-a8e1-db1e2f033364',
    endpoints: {
      graphApiUri: 'https://graph.microsoft.com',
    },
    apiAccessUrl: 'api://web-dev.timeghost.io/57b3475c-f720-4b10-a8e1-db1e2f033364/user_impersonation',
  },
  paddle: {
    vendorId: 958,
    product: {
      monthly: 73094,
      yearly: 73093,
    },
  },
  microsoft: {
    fulfillment:
      'https://portal.azure.com/#create/schgesellschaftfrprozessmanagementmbh1592311158227.timeghost_io/preview',
  },
  features: {
    extraLanguages: <string[]>['pt-PT'],
  },
  services: {
    webex: {
      clientId: 'C45d4396c5d000c62f998cd61a3db049a370bef5d0a547d5a6d1f5007c036879e',
      redirectUri: `${serverUrl}/Webex_Communications_Api`,
      scopes: ['meeting:schedules_read', 'meeting:participants_read', 'meeting:preferences_read'],
    },
  } as Config['services'],
  // @ts-ignore
  releaseName: null,
};
