import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, EventEmitter, Inject, inject, ErrorHandler, NgModule } from '@angular/core';
import produce from 'immer';
import { HubConnectionState, HubConnectionBuilder, LogLevel as LogLevel$1 } from '@microsoft/signalr';
import { Subject, fromEvent, of, firstValueFrom, from } from 'rxjs';
import { map, distinctUntilChanged, filter, debounceTime, switchMap, tap, mergeMap, catchError } from 'rxjs/operators';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpEventType, HTTP_INTERCEPTORS } from '@angular/common/http';
import { __decorate } from 'tslib';
import { EntityStore, StoreConfig, Store, QueryEntity, QueryConfig, Order, resetStores, Query, transaction, guid } from '@datorama/akita';
import { isValid, areIntervalsOverlapping, startOfDay, endOfDay } from 'date-fns';
import { v4 } from 'uuid';
import * as i2 from '@azure/msal-angular';
import { MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService } from '@azure/msal-angular';
import { set, cloneDeep } from 'lodash-es';
import { LogLevel as LogLevel$2, InteractionType, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';

/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 * Example usage:
 * ```
 * import { Logger } from 'timeghost-api';
 *
 * const log = new Logger('myFile');
 * ...
 * log.debug('something happened');
 * ```
 *
 * To disable debug and info logs in production, add this snippet to your root component:
 * ```
 * export class AppComponent implements OnInit {
 *   ngOnInit() {
 *     if (environment.production) {
 *       Logger.enableProductionMode();
 *     }
 *     ...
 *   }
 * }
 *
 * If you want to process logs through other outputs than console, you can add LogOutput functions to Logger.outputs.
 */
/**
 * The possible log levels.
 * LogLevel.Off is never emitted and only used with Logger.level property to disable logs.
 */
var LogLevel;
(function (LogLevel) {
  LogLevel[LogLevel["Off"] = 0] = "Off";
  LogLevel[LogLevel["Error"] = 1] = "Error";
  LogLevel[LogLevel["Warning"] = 2] = "Warning";
  LogLevel[LogLevel["Info"] = 3] = "Info";
  LogLevel[LogLevel["Debug"] = 4] = "Debug";
})(LogLevel || (LogLevel = {}));
class Logger {
  /**
   * Current logging level.
   * Set it to LogLevel.Off to disable logs completely.
   */
  static {
    this.level = LogLevel.Debug;
  }
  /**
   * Additional log outputs.
   */
  static {
    this.outputs = [];
  }
  /**
   * Enables production mode.
   * Sets logging level to LogLevel.Warning.
   */
  static enableProductionMode() {
    Logger.level = LogLevel.Warning;
  }
  constructor(source) {
    this.source = source;
  }
  /**
   * Logs messages or objects  with the debug level.
   * Works the same as console.log().
   */
  debug(...objects) {
    this.log(console.log, LogLevel.Debug, objects);
  }
  /**
   * Logs messages or objects  with the info level.
   * Works the same as console.log().
   */
  info(...objects) {
    this.log(console.info, LogLevel.Info, objects);
  }
  /**
   * Logs messages or objects  with the warning level.
   * Works the same as console.log().
   */
  warn(...objects) {
    this.log(console.warn, LogLevel.Warning, objects);
  }
  /**
   * Logs messages or objects  with the error level.
   * Works the same as console.log().
   */
  error(...objects) {
    this.log(console.error, LogLevel.Error, objects);
  }
  log(func, level, objects) {
    if (level <= Logger.level) {
      const log = this.source ? ["[" + this.source + "]"].concat(objects) : objects;
      func.apply(console, log);
      Logger.outputs.forEach(output => output.apply(output, [this.source, level].concat(objects)));
    }
  }
}
const LibConfig = new InjectionToken('Config');
const persistStateSettings = {
  include: ['clients', 'myTimes', 'projects', 'tags', 'userSettings', 'workspaces', 'applicationSettings'],
  enableInNonBrowser: true,
  preStorageUpdate(storeName, state) {
    if (storeName === 'myTimes') {
      let ret = {
        ...state
      };
      ret.nextDate = 'init';
      ret.loading = false;
      return ret;
    }
    return state;
  }
};
var PermissionRole$1;
(function (PermissionRole) {
  PermissionRole[PermissionRole["none"] = 0] = "none";
  PermissionRole[PermissionRole["manager"] = 1] = "manager";
})(PermissionRole$1 || (PermissionRole$1 = {}));
var WhoCanAddTasks;
(function (WhoCanAddTasks) {
  WhoCanAddTasks[WhoCanAddTasks["everyone"] = 0] = "everyone";
  WhoCanAddTasks[WhoCanAddTasks["pm_only"] = 1] = "pm_only";
  WhoCanAddTasks[WhoCanAddTasks["project_team"] = 2] = "project_team";
})(WhoCanAddTasks || (WhoCanAddTasks = {}));
var Scope;
(function (Scope) {
  Scope["Me"] = "User.Read";
  Scope["OutlookTasks"] = "Tasks.Read";
  Scope["Groups"] = "Group.Read.All";
  Scope["Calendar"] = "Calendars.Read";
  Scope["RecentFiles"] = "Files.Read.All";
  Scope["Messages"] = "Mail.ReadBasic";
  Scope["People"] = "People.Read";
  Scope["Users"] = "User.ReadBasic.All";
  Scope["OpenId"] = "openid";
  Scope["Profile"] = "profile";
  Scope["Sites"] = "Sites.Read.All";
  Scope["DevOpsOnline"] = "https://app.vssps.visualstudio.com/user_impersonation";
  Scope["TeamsCalls"] = "CallRecords.Read.All";
  Scope["GroupMemberServer"] = "GroupMember.Read.All.Application";
})(Scope || (Scope = {}));
var ExternalScope;
(function (ExternalScope) {
  ExternalScope["Webex"] = "webex";
  ExternalScope["WebexCalls"] = "webex.calls";
  ExternalScope["WebexCalendar"] = "webex.calendar";
})(ExternalScope || (ExternalScope = {}));
const ExternalUrls = {
  [ExternalScope.Webex]: {
    authorize({}) {
      return `/Webex_Authorize`;
    },
    scope: null
  },
  [ExternalScope.WebexCalls]: {
    authorize({}) {
      return `/Webex_Authorize`;
    },
    scope: 'calls'
  },
  [ExternalScope.WebexCalendar]: {
    authorize({}) {
      return `/Webex_Authorize`;
    },
    scope: 'calendar'
  }
};
var initialScopes = [Scope.Me, Scope.Calendar, Scope.OpenId, Scope.Profile, Scope.People, Scope.Users];
const devOpsScope = "499b84ac-1321-427f-aa17-267ca6975798/.default";
var TagType;
(function (TagType) {
  TagType[TagType["Project"] = 0] = "Project";
  TagType[TagType["Time"] = 1] = "Time";
})(TagType || (TagType = {}));
var PermissionRole;
(function (PermissionRole) {
  PermissionRole[PermissionRole["none"] = 0] = "none";
  PermissionRole[PermissionRole["manager"] = 1] = "manager";
})(PermissionRole || (PermissionRole = {}));
var AlertTypes;
(function (AlertTypes) {
  AlertTypes[AlertTypes["PROJECT"] = 0] = "PROJECT";
  AlertTypes[AlertTypes["TASK"] = 1] = "TASK";
})(AlertTypes || (AlertTypes = {}));
var ReminderByTheEndOfThe;
(function (ReminderByTheEndOfThe) {
  ReminderByTheEndOfThe[ReminderByTheEndOfThe["DAY"] = 0] = "DAY";
  ReminderByTheEndOfThe[ReminderByTheEndOfThe["WEEK"] = 1] = "WEEK";
})(ReminderByTheEndOfThe || (ReminderByTheEndOfThe = {}));
var AlertReceiver;
(function (AlertReceiver) {
  AlertReceiver[AlertReceiver["WORKSPACE_ADMIN"] = 0] = "WORKSPACE_ADMIN";
  AlertReceiver[AlertReceiver["PROJECT_MANAGER"] = 1] = "PROJECT_MANAGER";
  AlertReceiver[AlertReceiver["TEAM_MEMBER"] = 2] = "TEAM_MEMBER";
  AlertReceiver[AlertReceiver["GROUPS"] = 3] = "GROUPS";
})(AlertReceiver || (AlertReceiver = {}));
class GroupDefaults {
  static {
    this.everyoneGroupId = '00000000-0000-0000-0000-000000000000';
  }
  static {
    this.adminGroupId = '11111111-1111-1111-1111-111111111111';
  }
}
const clamp = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};
function dateIsValid(date) {
  return date instanceof Date && !isNaN(date.getTime());
}
const popupCenter = ({
  url,
  title,
  width: w,
  height: h
}) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(url, title, `
    scrollbars=yes,
    width=${w / systemZoom},
    height=${h / systemZoom},
    top=${top},
    left=${left}
    `);
  if (window.focus) newWindow.focus();
  return newWindow;
};
function isTimeTypeResponse(times) {
  return times && 'created' in times && 'type' in times;
}
const initialState$2 = {
  nextDate: "init",
  loading: false,
  error: null
};
let FeedStore = class FeedStore extends EntityStore {
  constructor() {
    super(initialState$2);
  }
  updateState(state) {
    this.update(state);
  }
  static {
    this.ɵfac = function FeedStore_Factory(t) {
      return new (t || FeedStore)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FeedStore,
      factory: FeedStore.ɵfac,
      providedIn: "root"
    });
  }
};
FeedStore = __decorate([StoreConfig({
  name: "feed"
})], FeedStore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FeedStore, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [], null);
})();
const initialState$1 = {
  nextDate: 'init',
  loading: false,
  error: null
};
let MyTimesStore = class MyTimesStore extends EntityStore {
  constructor() {
    super(initialState$1);
  }
  updateState(state) {
    this.update(state);
  }
  static {
    this.ɵfac = function MyTimesStore_Factory(t) {
      return new (t || MyTimesStore)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MyTimesStore,
      factory: MyTimesStore.ɵfac,
      providedIn: 'root'
    });
  }
};
MyTimesStore = __decorate([StoreConfig({
  name: 'myTimes'
})], MyTimesStore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MyTimesStore, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
let UserSettingsStore = class UserSettingsStore extends Store {
  constructor() {
    super({});
  }
  static {
    this.ɵfac = function UserSettingsStore_Factory(t) {
      return new (t || UserSettingsStore)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserSettingsStore,
      factory: UserSettingsStore.ɵfac,
      providedIn: "root"
    });
  }
};
UserSettingsStore = __decorate([StoreConfig({
  name: "userSettings"
})], UserSettingsStore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserSettingsStore, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [], null);
})();
let ProjectsStore = class ProjectsStore extends EntityStore {
  constructor() {
    super();
  }
  static {
    this.ɵfac = function ProjectsStore_Factory(t) {
      return new (t || ProjectsStore)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ProjectsStore,
      factory: ProjectsStore.ɵfac,
      providedIn: "root"
    });
  }
};
ProjectsStore = __decorate([StoreConfig({
  name: "projects"
})], ProjectsStore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProjectsStore, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [], null);
})();
let TagsStore = class TagsStore extends EntityStore {
  constructor() {
    super();
  }
  static {
    this.ɵfac = function TagsStore_Factory(t) {
      return new (t || TagsStore)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TagsStore,
      factory: TagsStore.ɵfac,
      providedIn: "root"
    });
  }
};
TagsStore = __decorate([StoreConfig({
  name: "tags"
})], TagsStore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TagsStore, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [], null);
})();
let TasksStore = class TasksStore extends EntityStore {
  constructor() {
    super();
  }
  static {
    this.ɵfac = function TasksStore_Factory(t) {
      return new (t || TasksStore)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TasksStore,
      factory: TasksStore.ɵfac,
      providedIn: "root"
    });
  }
};
TasksStore = __decorate([StoreConfig({
  name: "tasks"
})], TasksStore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TasksStore, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [], null);
})();
let WorkspacesStore = class WorkspacesStore extends EntityStore {
  constructor(userStore) {
    super();
    this.userStore = userStore;
  }
  static {
    this.ɵfac = function WorkspacesStore_Factory(t) {
      return new (t || WorkspacesStore)(i0.ɵɵinject(UserSettingsStore, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WorkspacesStore,
      factory: WorkspacesStore.ɵfac,
      providedIn: 'root'
    });
  }
};
WorkspacesStore = __decorate([StoreConfig({
  name: 'workspaces'
})], WorkspacesStore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WorkspacesStore, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: UserSettingsStore,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
let ClientsStore = class ClientsStore extends EntityStore {
  constructor() {
    super();
  }
  static {
    this.ɵfac = function ClientsStore_Factory(t) {
      return new (t || ClientsStore)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ClientsStore,
      factory: ClientsStore.ɵfac,
      providedIn: "root"
    });
  }
};
ClientsStore = __decorate([StoreConfig({
  name: "clients"
})], ClientsStore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClientsStore, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [], null);
})();
const initialState = {
  loading: false,
  error: null
};
let ComegoStore = class ComegoStore extends EntityStore {
  constructor() {
    super(initialState);
  }
  static {
    this.ɵfac = function ComegoStore_Factory(t) {
      return new (t || ComegoStore)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ComegoStore,
      factory: ComegoStore.ɵfac,
      providedIn: 'root'
    });
  }
};
ComegoStore = __decorate([StoreConfig({
  name: 'comego'
})], ComegoStore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComegoStore, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
let MyTimesQuery = class MyTimesQuery extends QueryEntity {
  constructor(store) {
    super(store);
    this.store = store;
  }
  getHasMore() {
    return this.getValue().nextDate != null;
  }
  getNextDate() {
    return this.getValue().nextDate;
  }
  selectAllGroupedByDay() {
    return this.selectAll().pipe(map(entries => {
      // this gives an object with dates as keys
      const groups = entries.reduce((groups, time) => {
        const date = time.end.split('T')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(time);
        return groups;
      }, {});
      // Edit: to add it in the array format instead
      return Object.keys(groups).map(date => {
        return {
          date,
          times: groups[date]
        };
      });
    }));
  }
  selectLatestRecording() {
    return this.selectEntity(e => e.end === null);
  }
  selectOverlaps(includeWorktimes) {
    return this.selectAll().pipe(distinctUntilChanged((prev, next) => {
      const prevJ = prev.map(({
        start,
        end
      }) => ({
        start,
        end
      }));
      const nextJ = next.map(({
        start,
        end
      }) => ({
        start,
        end
      }));
      return JSON.stringify(prevJ) === JSON.stringify(nextJ);
    }), map(times => times.filter(t => t.start && t.end && isValid(new Date(t.start)) && isValid(new Date(t.end))).filter((t, i, arr) => {
      const start = new Date(t.start).getTime(),
        end = new Date(t.end).getTime();
      return !!arr.find(x => {
        const vstart = Date.parse(x.start),
          vend = Date.parse(x.end);
        return x.id !== t.id && areIntervalsOverlapping({
          start,
          end
        }, {
          start: vstart,
          end: vend
        });
      });
    })));
  }
  getLatestRecording() {
    return this.getAll({
      filterBy: e => e.end === null,
      limitTo: 1
    })[0];
  }
  static {
    this.ɵfac = function MyTimesQuery_Factory(t) {
      return new (t || MyTimesQuery)(i0.ɵɵinject(MyTimesStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MyTimesQuery,
      factory: MyTimesQuery.ɵfac,
      providedIn: 'root'
    });
  }
};
MyTimesQuery = __decorate([QueryConfig({
  sortBy: 'end',
  sortByOrder: Order.DESC
})], MyTimesQuery);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MyTimesQuery, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: MyTimesStore
  }], null);
})();
const log$d = new Logger('NotifyService');
var signalTypes;
(function (signalTypes) {
  signalTypes[signalTypes["Added"] = 1] = "Added";
  signalTypes[signalTypes["Updated"] = 2] = "Updated";
  signalTypes[signalTypes["Deleted"] = 3] = "Deleted";
  signalTypes[signalTypes["Error"] = 4] = "Error";
})(signalTypes || (signalTypes = {}));
class NotifyService {
  constructor(config, httpClient, feedStore, myTimesStore, userSettingsStore, projectsStore, tagsStore, tasksStore, workspacesStore, clientsStore, comegoStore, myTimesQuery) {
    this.config = config;
    this.httpClient = httpClient;
    this.feedStore = feedStore;
    this.myTimesStore = myTimesStore;
    this.userSettingsStore = userSettingsStore;
    this.projectsStore = projectsStore;
    this.tagsStore = tagsStore;
    this.tasksStore = tasksStore;
    this.workspacesStore = workspacesStore;
    this.clientsStore = clientsStore;
    this.comegoStore = comegoStore;
    this.myTimesQuery = myTimesQuery;
    this.netState = new Subject();
    this.netState$ = this.netState.asObservable();
    this.subscriptions = [];
    this.onMessage = new EventEmitter(true);
    this.onHubClose = new Subject();
    this.onHubClose$ = this.onHubClose.asObservable().pipe(filter(() => this.isInstanceConnected()));
    if ('ononline' in window && 'onoffline' in window) {
      fromEvent(window, 'online').subscribe(ev => this.netState.next(ev)), fromEvent(window, 'offline').subscribe(ev => this.netState.next(ev));
    }
    //https://github.com/anthonychu/cosmosdb-signalr-realtime-updates/blob/master/www/index.html
  }
  /**
   * connectionId represents connection state (null = disconnected/auth skipped)
   */
  isInstanceConnected() {
    return this._hubConnection && this._hubConnection.connectionId != null && [HubConnectionState.Connected, HubConnectionState.Connecting, HubConnectionState.Reconnecting].includes(this._hubConnection.state);
  }
  init() {
    this.netState.pipe(map(x => x.type === 'online' && x.returnValue === true), distinctUntilChanged(), debounceTime(1000), switchMap(x => {
      if (!!x && !this.isInstanceConnected()) {
        return this.createNewInstance(!this._hubConnection);
      } else if (!x && this.isInstanceConnected()) {
        this._hubConnection.stop();
        this._hubConnection = null;
      }
      return of();
    })).subscribe();
    return new Promise((resolve, reject) => {
      this.createNewInstance(true).then(() => resolve(null)).catch(err => {
        reject(err);
        location.reload();
      });
    });
  }
  _createHub(url) {
    return new HubConnectionBuilder().withUrl(url, {
      accessTokenFactory: async () => {
        return this.accessToken;
      },
      logger: LogLevel$1.None
    }).withAutomaticReconnect({
      nextRetryDelayInMilliseconds: retryContext => {
        if (retryContext.retryReason.message === 'WebSocket is not in the OPEN state') return 0;
        return clamp(retryContext.previousRetryCount * 3000, 0, 20 * 1000);
      }
    }).build();
  }
  createNewInstance(createNewHub = false) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.config.serverUrl + '/start_realtime', null).subscribe(async info => {
        this.accessToken = info.accessToken;
        if (this._hubConnection?.connectionId) await this._hubConnection.stop();
        if (createNewHub || !this._hubConnection?.connectionId) this._hubConnection = this._createHub(info.url);
        this._hubConnection.on('close', err => {
          if (err) reject(err);else this.createNewInstance(true).then(resolve).catch(reject);
          log$d.info('Live Synchronization (SignalR) expired/closed');
        });
        await this._hubConnection.start().then(() => {
          log$d.debug('Real-time connection started!');
          resolve(null);
        }).catch(error => {
          log$d.error('Error while establishing connection :(', error);
          reject(error);
        });
        this._hubConnection.on('changes', data => {
          this.handleSignalRChangeMessages(data);
        });
      }, error => {
        log$d.error('Error while establishing connection :(', error);
        reject(error);
      });
    });
  }
  handleSignalRChangeMessages(message) {
    if (message.payload.cosmosEntityName == 'times') {
      if (message.type == signalTypes.Added) {
        this.addTimeMessage(message.payload);
      } else if (message.type == signalTypes.Updated) {
        this.updateTimeMessage(message.payload);
      } else if (message.type == signalTypes.Deleted) {
        this.deleteTimeMessage(message.payload);
      }
    } else if (message.payload.cosmosEntityName == 'users') {
      let newUserSettings = message.payload;
      if (newUserSettings.id == this.userSettingsStore.getValue().id) {
        this.userSettingsStore.update(newUserSettings);
      }
    } else {
      this.handleGenericTypeMessage(message.type, message.payload);
    }
    this.onMessage.emit(message);
  }
  handleGenericTypeMessage(type, payload) {
    if (!payload) return;
    const userSettings = this.userSettingsStore.getValue();
    if (payload.cosmosEntityName !== 'workspaces' && payload.workspace.id !== userSettings.workspace.id) return;
    payload = this.filterWithoutData(payload, userSettings?.settings.languageSetting);
    const store = {
      projects: this.projectsStore,
      tags: this.tagsStore,
      tasks: this.tasksStore,
      workspaces: this.workspacesStore,
      clients: this.clientsStore,
      comego: this.comegoStore
    }[payload.cosmosEntityName];
    if (!store) return;
    if ('workspace' in payload && payload.workspace?.id !== userSettings.workspace.id) {
      this.onMessage.emit({
        payload: {
          code: 401,
          message: 'errors.workspace-differs'
        },
        type: signalTypes.Error
      });
      return;
    }
    if (type == signalTypes.Added) {
      store.add(payload);
    } else if (type == signalTypes.Updated) {
      store.upsert(payload.id, payload);
      if (payload.cosmosEntityName == 'workspaces') {
        const currentWorkspaceId = userSettings?.workspace?.id;
        if (!currentWorkspaceId) return;
        if (payload.id === currentWorkspaceId) {
          this.userSettingsStore.update({
            workspace: payload
          });
        }
      }
    } else if (type == signalTypes.Deleted) {
      store.remove(payload.id);
    }
  }
  filterWithoutData(payload, timeghostLanguageSetting) {
    if (payload.entityName == 'times') {
      if (payload.client?.name == 'No Client') {
        payload.client.name = timeghostLanguageSetting.indexOf('DE') != -1 ? 'Ohne Kunde' : 'Without Client';
      }
      if (payload.project.name == 'No Project') {
        payload.project.name = timeghostLanguageSetting.indexOf('DE') != -1 ? 'Ohne Projekt' : 'Without Project';
      }
    } else if (payload.entityName == 'projects') {
      if (payload.client.name == 'No Client') {
        payload.client.name = timeghostLanguageSetting.indexOf('DE') != -1 ? 'Ohne Kunde' : 'Without Client';
      }
      if (payload.name == 'No Project') {
        payload.name = timeghostLanguageSetting.indexOf('DE') != -1 ? 'Ohne Projekt' : 'Without Project';
      }
    } else if (payload.entityName == 'tasks') {
      if (payload.client.name == 'No Client') {
        payload.client.name = timeghostLanguageSetting.indexOf('DE') != -1 ? 'Ohne Kunde' : 'Without Client';
      }
      if (payload.project.name == 'No Project') {
        payload.project.name = timeghostLanguageSetting.indexOf('DE') != -1 ? 'Ohne Projekt' : 'Without Project';
      }
    }
    return payload;
  }
  addTimeMessage(time) {
    const userSettings = this.userSettingsStore.getValue();
    const currentWorkspaceId = userSettings?.workspace?.id;
    if (!currentWorkspaceId) return;
    if (time.user.id == userSettings.id && time.workspace.id == currentWorkspaceId) {
      if (time.outlookCalenderReference) this.feedStore.upsert(time.outlookCalenderReference, {
        booked: true
      });
      this.myTimesStore.add(time);
    }
  }
  updateTimeMessage(time) {
    const userSettings = this.userSettingsStore.getValue();
    const currentWorkspaceId = userSettings?.workspace?.id;
    if (time.user.id == userSettings.id && time.workspace.id == currentWorkspaceId) {
      if (time.outlookCalenderReference) this.feedStore.upsert(time.outlookCalenderReference, {
        booked: true
      });
      this.myTimesStore.update(time.id, time);
    }
  }
  deleteTimeMessage(time) {
    const userSettings = this.userSettingsStore.getValue();
    const currentWorkspaceId = userSettings?.workspace?.id;
    time = this.myTimesQuery.getEntity(time.id);
    if (time && time.user.id == userSettings.id && time.workspace.id == currentWorkspaceId) {
      if (time.outlookCalenderReference) this.feedStore.upsert(time.outlookCalenderReference, {
        booked: false
      });
    }
    this.myTimesStore.remove(time.id);
  }
  static {
    this.ɵfac = function NotifyService_Factory(t) {
      return new (t || NotifyService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(FeedStore), i0.ɵɵinject(MyTimesStore), i0.ɵɵinject(UserSettingsStore), i0.ɵɵinject(ProjectsStore), i0.ɵɵinject(TagsStore), i0.ɵɵinject(TasksStore), i0.ɵɵinject(WorkspacesStore), i0.ɵɵinject(ClientsStore), i0.ɵɵinject(ComegoStore), i0.ɵɵinject(MyTimesQuery));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NotifyService,
      factory: NotifyService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotifyService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: FeedStore
  }, {
    type: MyTimesStore
  }, {
    type: UserSettingsStore
  }, {
    type: ProjectsStore
  }, {
    type: TagsStore
  }, {
    type: TasksStore
  }, {
    type: WorkspacesStore
  }, {
    type: ClientsStore
  }, {
    type: ComegoStore
  }, {
    type: MyTimesQuery
  }], null);
})();
let UserImagesStore = class UserImagesStore extends EntityStore {
  constructor() {
    super();
  }
  static {
    this.ɵfac = function UserImagesStore_Factory(t) {
      return new (t || UserImagesStore)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserImagesStore,
      factory: UserImagesStore.ɵfac,
      providedIn: "root"
    });
  }
};
UserImagesStore = __decorate([StoreConfig({
  name: "userImages"
})], UserImagesStore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserImagesStore, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [], null);
})();
let UserImagesQuery = class UserImagesQuery extends QueryEntity {
  constructor(store) {
    super(store);
    this.store = store;
  }
  static {
    this.ɵfac = function UserImagesQuery_Factory(t) {
      return new (t || UserImagesQuery)(i0.ɵɵinject(UserImagesStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserImagesQuery,
      factory: UserImagesQuery.ɵfac,
      providedIn: "root"
    });
  }
};
UserImagesQuery = __decorate([QueryConfig({})], UserImagesQuery);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserImagesQuery, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: UserImagesStore
  }], null);
})();
const log$c = new Logger('UserService');
class UserService {
  constructor(config, persistStorage, httpClient, authService, userSettingsStore, projectsStore, userImagesStore, userImagesQuery) {
    this.config = config;
    this.persistStorage = persistStorage;
    this.httpClient = httpClient;
    this.authService = authService;
    this.userSettingsStore = userSettingsStore;
    this.projectsStore = projectsStore;
    this.userImagesStore = userImagesStore;
    this.userImagesQuery = userImagesQuery;
    this.BlobToUrl = image => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          resolve(reader.result);
        }, false);
        reader.readAsDataURL(image);
      });
    };
  }
  get adapter() {
    return this.httpClient;
  }
  /**
   * Get the current user profile object.
   */
  getCurrentUser() {
    return this.httpClient.get(this.config.serverUrl + '/get/currentuser').pipe(tap(userSettings => {
      this.userSettingsStore.update(userSettings);
      if (userSettings.officeProfile && userSettings.officeProfile.displayName) {
        log$c.debug('send user to analytics');
        //interne User nicht an google analytics senden
        if (userSettings.email.toLocaleLowerCase().indexOf('sharepoint-template.com') != -1 || userSettings.email.toLocaleLowerCase().indexOf('timeghost.io') != -1 || userSettings.email.toLocaleLowerCase().indexOf('@m365x') != -1) {
          window.dataLayer.push({
            Internal_Users: 'Yes'
          });
        } else {
          window.dataLayer.push({
            Internal_Users: 'No'
          });
        }
        const workspaceUser = userSettings.workspace.users.find(x => x.id == userSettings.id);
        window.dataLayer.push({
          user_id: userSettings.id
        });
        window.dataLayer.push({
          user_email: userSettings.email
        });
        window.dataLayer.push({
          user_properties: {
            'First name': userSettings.officeProfile.givenName,
            'Last name': userSettings.officeProfile.surname,
            Email: userSettings.email,
            company: {
              Name: userSettings.organization ? userSettings.organization.displayName : userSettings.email.split('@')[1].toLowerCase()
            }
          }
        });
        window.dataLayer.push({
          User_Workspace_Role: workspaceUser.admin ? 'is_admin' : 'is_member'
        });
        if (userSettings.workspace.subscription) {
          let statusText = userSettings.workspace.subscription.status;
          if (userSettings.workspace.users.length == 1) statusText += '_1';else if (userSettings.workspace.users.length < 5) statusText += '_<5';else if (userSettings.workspace.users.length < 10) statusText += '_<10';else if (userSettings.workspace.users.length < 20) statusText += '_<20';else if (userSettings.workspace.users.length < 30) statusText += '_<30';else if (userSettings.workspace.users.length < 40) statusText += '_<40';else if (userSettings.workspace.users.length < 50) statusText += '_<50';else if (userSettings.workspace.users.length < 100) statusText += '_<100';else if (userSettings.workspace.users.length >= 100) statusText += '_>100';
          window.dataLayer.push({
            Subscription_Status: statusText
          });
        }
      }
      var dif = new Date(userSettings.created).getTime() -
      //@ts-ignore
      new Date(userSettings.modified).getTime();
      var Seconds_from_T1_to_T2 = dif / 1000;
      var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
      if (Seconds_Between_Dates < 2) {
        window.dataLayer.push({
          login_event: 'login'
        });
      }
    }, error => this.userSettingsStore.setError(error)), mergeMap(userSettings => {
      if (userSettings.webex?.enabled) {
        return this.httpClient.get(this.config.serverUrl + '/Webex_Token').pipe(map(tokens => {
          const _userSettings = {
            ...userSettings,
            webex: {
              ...userSettings.webex,
              tokens
            }
          };
          this.userSettingsStore.update(_userSettings);
          return _userSettings;
        }), catchError(() => of(userSettings)));
      }
      return of(userSettings);
    }));
  }
  updateUserToken(type, data) {
    const newSettings = produce(this.userSettingsStore.getValue(), state => {
      if (type === "webex") state.webex = {
        userId: data.id,
        orgId: data.orgId || null
      };
    });
    return firstValueFrom(this.httpClient.post(this.config.serverUrl + '/update/users/' + newSettings.id, newSettings).pipe(tap(userSettings => this.userSettingsStore.update(userSettings), error => this.userSettingsStore.setError(error))));
  }
  teamsLogout() {
    this.persistStorage?.clearStore(); // clear default/initial state
    resetStores(); // reset stores to default/initial state
    this.authService.logout();
    localStorage.setItem('teams_auth_scopes', initialScopes.join(','));
    localStorage.setItem('teams_auth_clientId', this.config.clientId);
    window.location.href = window.location.origin + '/teams-auth-iframe.html';
  }
  /**
   * Get the current user photo as blob object.
   */
  getCurrentUserPhoto(size = '48x48') {
    return this.getUserPhotoById(this.userSettingsStore.getValue().id, size);
  }
  getUserPhotoById(userId, size = '48x48') {
    return new Promise((resolve, reject) => {
      let blob = this.userImagesQuery.getEntity(userId + '_' + size);
      if (blob) {
        resolve(blob.image);
        return;
      }
      this.httpClient.get('https://graph.microsoft.com/v1.0/users/' + userId + '/photo' + (size ? 's/' + size : '') + '/$value', {
        responseType: 'blob'
      }).subscribe(x => {
        this.BlobToUrl(x).then(y => {
          this.userImagesStore.add({
            id: userId + '_' + size,
            image: y
          });
          resolve(y);
        }).catch(() => {
          this.userImagesStore.add({
            id: userId + '_' + size,
            image: '/assets/img/photo.jpg'
          });
          resolve('/assets/img/photo.jpg');
        });
      }, () => {
        this.userImagesStore.add({
          id: userId + '_' + size,
          image: '/assets/img/photo.jpg'
        });
        resolve('/assets/img/photo.jpg');
      });
    });
  }
  getPeople(name, skip = 0) {
    let url = 'https://graph.microsoft.com/v1.0/me/people';
    url += '?$skip=' + skip;
    if (name) url += '&$search=' + name;
    return this.runGraphApiRequest(url);
  }
  getTeamsGroups(name) {
    return new Promise((resolve, reject) => {
      if (!this.userSettingsStore.getValue().enabledGraphScopes.includes(Scope.GroupMemberServer)) {
        this.addScope(Scope.GroupMemberServer).then(() => {
          return this.getTeamsGroups(name);
        }).then(resolve, reject);
        return;
      }
      if (!this.userSettingsStore.getValue().enabledGraphScopes.includes(Scope.Groups)) {
        this.addScope(Scope.Groups).then(() => {
          return this.getTeamsGroups(name);
        }).then(resolve).catch(reject);
        return;
      }
      let url = "https://graph.microsoft.com/beta/groups?$filter=resourceProvisioningOptions/Any(x:x eq 'Team')&$select=id,displayName,description";
      let headers = null;
      if (name) {
        url += '&$search="displayName:' + name + '"';
        headers = new HttpHeaders({
          ConsistencyLevel: 'eventual'
        });
      }
      this.runGraphApiRequest(url, headers).then(x => resolve(x), e => reject(e));
    });
  }
  changeWorkspace(workspace) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'change_workspace'
    });
    return new Promise((resolve, reject) => {
      const newValue = produce(this.userSettingsStore.getValue(), draft => {
        draft.defaultWorkspace = workspace.id;
      });
      this.httpClient.post(this.config.serverUrl + '/update/users/' + newValue.id, newValue).pipe(switchMap(() => this.getCurrentUser())).subscribe(userSettings => {
        if (userSettings) this.userSettingsStore.update(userSettings);
        resolve(userSettings);
      }, error => {
        this.userSettingsStore.setError(error);
        reject(error);
      });
    });
  }
  updateGraphProfile() {
    this.httpClient.get('https://graph.microsoft.com/v1.0/me').subscribe(profile => {
      if (JSON.stringify(this.userSettingsStore.getValue().officeProfile) !== JSON.stringify(profile)) {
        const newValue = produce(this.userSettingsStore.getValue(), state => {
          state.officeProfile = profile, state.email = profile.userPrincipalName;
        });
        this.httpClient.post(this.config.serverUrl + '/update/users/' + newValue.id, newValue).pipe(tap(userSettings => this.userSettingsStore.update(userSettings), error => this.userSettingsStore.setError(error))).subscribe(() => {}, error => {
          console.error(error);
        });
      }
    }, error => {
      console.error(error);
    });
  }
  changeSettings(settings) {
    const newSettings = produce(this.userSettingsStore.getValue(), state => {
      state.settings = {
        ...state.settings,
        ...Object.entries(settings).filter(([, value]) => value !== undefined).reduce((l, [key, value]) => ({
          ...l,
          [key]: value
        }), state.settings || {})
      };
    });
    return this.httpClient.post(this.config.serverUrl + '/update/users/' + newSettings.id, newSettings).pipe(tap(userSettings => this.userSettingsStore.update(userSettings), error => this.userSettingsStore.setError(error)));
  }
  async authorizeExternalScope(service, askScopes, opts) {
    const serviceName = service.split('.')[0];
    const config = this.config.services?.[serviceName];
    if (!config) return;
    const urls = ExternalUrls[service];
    if (!urls) return;
    const defaultScopes = config.scopes || [];
    const scopes = askScopes?.reduce((acc, r) => {
      if (acc.findIndex(s => s === r) === -1) acc.push(r);
      return acc;
    }, defaultScopes) || defaultScopes;
    const options = opts || {};
    return await new Promise(async (resolve, reject) => {
      const {
        url: authorizeUrl
      } = await firstValueFrom(this.httpClient.get(this.config.serverUrl + urls.authorize({}), {
        params: {
          redirectUri: config.redirectUri,
          scope: urls.scope
        }
      }));
      if (this._currentWnd?.closed) {
        this._currentWnd = null;
      }
      const wnd = this._currentWnd = popupCenter({
        url: authorizeUrl,
        title: `${serviceName.toUpperCase()} Authorize`,
        height: 500,
        width: 400
      });
      const destroyWnd = () => {
        !wnd.closed && wnd?.close(), timer && clearInterval(timer);
      };
      wnd.addEventListener("close", () => {
        destroyWnd();
        options.onClose?.();
        resolve();
      }, {
        passive: true
      });
      const timer = setInterval(() => {
        if (!this._currentWnd || wnd.closed) {
          timer && clearInterval(timer);
          this._currentWnd = null;
          options.onClose?.();
          resolve();
        }
      }, 500);
      return {
        close: () => {
          destroyWnd();
          resolve();
        }
      };
    }).catch(err => {
      this._currentWnd?.close?.();
      this._currentWnd = null;
      throw err;
    });
  }
  addScope(scope) {
    return new Promise((resolve, reject) => {
      const currentUserSettings = this.userSettingsStore.getValue();
      if (currentUserSettings.enabledGraphScopes.indexOf(scope) !== -1) {
        resolve(null);
        return;
      }
      this.consentNewScopes([scope]).then(() => {
        this.addScopeToProfile(currentUserSettings, scope).subscribe(() => resolve(null));
      }).catch(err => {
        reject(err);
      });
    });
  }
  addScopeToProfile(currentUserSettings, scope) {
    let enabledGraphScopes = [...currentUserSettings.enabledGraphScopes];
    enabledGraphScopes.push(scope);
    if (scope == Scope.DevOpsOnline) {
      enabledGraphScopes.push(devOpsScope);
    }
    const newValue = produce(this.userSettingsStore.getValue(), entity => {
      entity.enabledGraphScopes = enabledGraphScopes;
    });
    return this.httpClient.post(this.config.serverUrl + '/update/users/' + currentUserSettings.id, newValue).pipe(tap(userSettings => {
      this.userSettingsStore.update(userSettings);
    }, error => {
      this.userSettingsStore.setError(error);
    }));
  }
  removeScope(scope) {
    const currentUserSettings = this.userSettingsStore.getValue();
    if (!currentUserSettings.enabledGraphScopes) {
      currentUserSettings.enabledGraphScopes = [];
    }
    let enabledGraphScopes = [...currentUserSettings.enabledGraphScopes];
    enabledGraphScopes = enabledGraphScopes.filter(value => scope != value);
    if (scope == Scope.DevOpsOnline) {
      enabledGraphScopes = enabledGraphScopes.filter(value => devOpsScope != value);
    }
    const newValue = produce(this.userSettingsStore.getValue(), entity => {
      entity.enabledGraphScopes = enabledGraphScopes;
    });
    return this.httpClient.post(this.config.serverUrl + '/update/users/' + currentUserSettings.id, newValue).pipe(tap(userSettings => {
      this.userSettingsStore.update(userSettings);
    }, error => {
      this.userSettingsStore.setError(error);
    }));
  }
  async getResource(resource, resourceArgs = {}) {
    return await firstValueFrom(this.httpClient.get(this.config.serverUrl + '/Get_Graph_Subscription?' + new URLSearchParams({
      resource,
      ...resourceArgs
    }).toString()));
  }
  consentNewScopes(scopes) {
    return new Promise(async (resolve, reject) => {
      if (scopes.length > 1 && scopes.includes(Scope.TeamsCalls)) throw 'TeamsCall consent can only approven allown';
      if (scopes.length > 1 && scopes.includes(Scope.GroupMemberServer)) throw 'GroupMember consent can only approven allown';
      if (scopes.includes(Scope.TeamsCalls) || scopes.includes(Scope.GroupMemberServer)) {
        let resource = scopes.includes(Scope.TeamsCalls) ? 'communications/callRecords' : 'groups';
        let client_id = scopes.includes(Scope.TeamsCalls) ? '920ade8c-385f-4693-9f34-e2b4906bf009' : '84b47222-4538-490e-a73f-bf969f1f9483';
        await this.getResource(resource).then(() => resolve(null)).catch(async err => {
          if (err.status === 404) await firstValueFrom(this.httpClient.post(this.config.serverUrl + '/Add_Graph_Subscription?resource=' + resource, null)).catch(async e => {
            if (window['teams']) {
              const windowRef = window.open('https://login.microsoftonline.com/common/adminconsent?client_id=' + client_id, '_blank');
              windowRef.addEventListener("close", () => reject('Consent opened'), {
                once: true
              });
              await this.getResource(resource).then(resolve).catch(reject); // check again or reject
            } else {
              location.href = 'https://login.microsoftonline.com/common/adminconsent?client_id=' + client_id;
            }
            reject('Consent opened');
            return;
          });
        });
      } else {
        if (window['teams']) {
          this.getPermissionInTeams(scopes).then(() => {
            resolve(null);
          }).catch(error => {
            log$c.error({
              error
            });
            reject(error);
          });
        } else {
          this.authService.acquireTokenPopup({
            scopes: scopes
          }).subscribe(() => {
            resolve(null);
          }, error => {
            reject(error);
          });
        }
      }
    });
  }
  /**
   *
   * @param scopes
   * @param options external options should be true in some cases of scope auth, since teams blocks popup from time to time.
   * @returns
   */
  getPermissionInTeams(scopes, {
    isExternal,
    ...options
  } = {
    isExternal: false
  }) {
    return new Promise((resolve, reject) => {
      const scopeString = scopes.join(',');
      localStorage.setItem(this.config.teamsConfig?.storageScopesKey ?? 'teams_auth_scopes', scopeString);
      localStorage.setItem(this.config.teamsConfig?.storageClientIdKey ?? 'teams_auth_clientId', this.config.clientId);
      window.microsoftTeams.authentication.authenticate({
        ...options,
        isExternal: this.config.teamsConfig?.externalPopups ?? isExternal,
        url: window.location.origin + (this.config.teamsConfig?.teamsAuthPath ?? `/teams-auth.html?${new URLSearchParams({
          closeOnSuccess: "true",
          clientId: this.config.clientId,
          scopes: scopeString
        }).toString()}`).replace(/^(\/+)?/, "/")
      }).then(resolve).catch(reject);
    });
  }
  getOutlookFolder() {
    let url = 'https://graph.microsoft.com/v1.0/me/mailFolders';
    return this.runGraphApiRequest(url);
  }
  setFeedOutlookFolder(outlookMailFolderId) {
    const newValue = produce(this.userSettingsStore.getValue(), state => {
      state.settings.outlookMailFolderId = outlookMailFolderId;
    });
    return this.httpClient.post(this.config.serverUrl + '/update/users/' + newValue.id, newValue).pipe(tap(userSettings => this.userSettingsStore.update(userSettings), error => this.userSettingsStore.setError(error)));
  }
  getFrillSsoToken() {
    return this.httpClient.get(this.config.serverUrl + '/get_frill_ssotoken');
  }
  setFeedAdditionaCalendars(additionalCalendarIds) {
    const newValue = produce(this.userSettingsStore.getValue(), state => {
      state.settings.additionalCalendarIds = additionalCalendarIds;
    });
    return this.httpClient.post(this.config.serverUrl + '/update/users/' + newValue.id, newValue).pipe(tap(userSettings => this.userSettingsStore.update(userSettings), error => this.userSettingsStore.setError(error)));
  }
  getUserCalendars() {
    let url = 'https://graph.microsoft.com/v1.0/me/calendars';
    return this.runGraphApiRequest(url);
  }
  runGraphApiRequest(url, headers) {
    return new Promise(async (resolve, reject) => {
      let data = [];
      let runWhile = true;
      while (runWhile) {
        try {
          var retData = await this.httpClient.get(url, {
            headers: headers
          }).toPromise();
          if (retData['@odata.nextLink'] && retData['@odata.nextLink'] != url) {
            url = retData['@odata.nextLink'];
            data = data.concat(retData.value);
          } else {
            runWhile = false;
            data = data.concat(retData.value);
            resolve(data);
          }
        } catch (error) {
          runWhile = false;
          reject(error);
        }
      }
    });
  }
  addApiToken(name) {
    return new Promise((resolve, reject) => {
      let newToken = v4() + '-' + this.userSettingsStore.getValue().id;
      let newPosition = this.userSettingsStore.getValue().apiTokens.length;
      const newValue = produce(this.userSettingsStore.getValue(), state => {
        if (!state.apiTokens) state.apiTokens = [];
        state.apiTokens.push({
          name: name,
          position: newPosition,
          token: newToken
        });
      });
      this.httpClient.post(this.config.serverUrl + '/update/users/' + newValue.id, newValue).pipe(tap(userSettings => this.userSettingsStore.update(userSettings), error => this.userSettingsStore.setError(error))).subscribe(() => {
        resolve({
          name: name,
          position: newPosition,
          token: newToken
        });
      }, error => reject(error));
    });
  }
  deleteApiToken(position) {
    const newValue = produce(this.userSettingsStore.getValue(), state => {
      state.apiTokens = state.apiTokens.filter(x => x.position != position);
    });
    return this.httpClient.post(this.config.serverUrl + '/update/users/' + newValue.id, newValue).pipe(tap(userSettings => this.userSettingsStore.update(userSettings), error => this.userSettingsStore.setError(error)));
  }
  addPinnedProject(projectId) {
    return this.updatePinnedProjects([...(this.userSettingsStore.getValue().pinnedProjects?.filter(x => x != projectId) || []), projectId]);
  }
  updatePinnedProjects(ids) {
    const newValue = produce(this.userSettingsStore.getValue(), state => {
      state.pinnedProjects = (ids || []).filter(pid => this.projectsStore.getValue()?.ids && this.projectsStore.getValue().ids.indexOf(pid) !== -1);
    });
    return this.httpClient.post(this.config.serverUrl + '/update/users/' + newValue.id, newValue).pipe(tap(userSettings => this.userSettingsStore.update(userSettings), error => this.userSettingsStore.setError(error)));
  }
  removePinnedProject(projectId) {
    return this.updatePinnedProjects(this.userSettingsStore.getValue().pinnedProjects?.filter(x => x != projectId) || []);
  }
  removeExternalScope(scopeName) {
    const currentUserSettings = this.userSettingsStore.getValue();
    const scopeTokens = scopeName.split('.');
    const service = scopeTokens[0];
    const scope = scopeTokens[1];
    if (!currentUserSettings.webex) {
      return;
    }
    const newValue = produce(this.userSettingsStore.getValue(), state => {
      if (service === 'webex') {
        state.webex.scopes = state.webex.scopes.filter(_scope => _scope !== scope);
      }
    });
    return this.httpClient.post(this.config.serverUrl + '/update/users/' + currentUserSettings.id, newValue).pipe(tap(userSettings => this.userSettingsStore.update(userSettings), error => this.userSettingsStore.setError(error)));
  }
  static {
    this.ɵfac = function UserService_Factory(t) {
      return new (t || UserService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject('persistStorage', 8), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MsalService), i0.ɵɵinject(UserSettingsStore), i0.ɵɵinject(ProjectsStore), i0.ɵɵinject(UserImagesStore), i0.ɵɵinject(UserImagesQuery));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserService,
      factory: UserService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: ['persistStorage']
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: i2.MsalService
  }, {
    type: UserSettingsStore
  }, {
    type: ProjectsStore
  }, {
    type: UserImagesStore
  }, {
    type: UserImagesQuery
  }], null);
})();
class UserSettingsQuery extends Query {
  constructor(store) {
    super(store);
    this.store = store;
  }
  get isWorkspaceAdmin() {
    return this.getValue().workspace.users.findIndex(x => x.id === this.getValue().id) !== -1;
  }
  get groups() {
    return this.getValue().workspace.groups.filter(x => x.users.findIndex(z => z.id === this.getValue().id) != -1);
  }
  static {
    this.ɵfac = function UserSettingsQuery_Factory(t) {
      return new (t || UserSettingsQuery)(i0.ɵɵinject(UserSettingsStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserSettingsQuery,
      factory: UserSettingsQuery.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserSettingsQuery, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: UserSettingsStore
  }], null);
})();
const log$b = new Logger('BaseService');
const isTeamsWindow = () => {
  if (window.parent === window.self && window.nativeInterface || window.name === 'embedded-page-container' || window.name === 'extension-tab-frame') {
    return true;
  }
  return !!window.teams || !!JSON.parse(sessionStorage.teams || 'false');
};
class BaseService {
  constructor(config, persistStorage, notifyService, userService, userSettingsQuery, userSettingsStore, authService, httpClient) {
    this.config = config;
    this.persistStorage = persistStorage;
    this.notifyService = notifyService;
    this.userService = userService;
    this.userSettingsQuery = userSettingsQuery;
    this.userSettingsStore = userSettingsStore;
    this.authService = authService;
    this.httpClient = httpClient;
    this.initService = false;
  }
  init() {
    if (this.initService) {
      return new Promise(r => {
        r(null);
      });
    }
    this.initService = true;
    return Promise.resolve();
  }
  async checkConsent() {
    return await new Promise((r, re) => {
      //check consent
      const userSettings = this.userSettingsQuery.getValue();
      const enabledGraphScopes = [...userSettings.enabledGraphScopes];
      let promises = [];
      for (let index = 0; index < enabledGraphScopes.length; index++) {
        const element = enabledGraphScopes[index];
        var x = new Promise((resolve, reject) => {
          if (element == Scope.TeamsCalls) {
            resolve(element);
          } else if (element == Scope.GroupMemberServer) {
            resolve(element);
          } else this.authService.acquireTokenSilent({
            scopes: [element]
          }).subscribe(x => resolve(element), e => resolve(null));
        });
        promises.push(x);
      }
      Promise.all(promises).then(successfullyTestedScopes => {
        successfullyTestedScopes = successfullyTestedScopes.filter(e => e != null);
        successfullyTestedScopes = successfullyTestedScopes.slice().sort();
        if (!(enabledGraphScopes.length === successfullyTestedScopes.length && enabledGraphScopes.slice().sort().every(function (value, index) {
          return value === successfullyTestedScopes[index];
        }))) {
          const newValue = produce(this.userSettingsStore.getValue(), entity => {
            entity.enabledGraphScopes = successfullyTestedScopes;
          });
          this.updateUserSettings(userSettings.id, newValue).then(userSettings => {
            this.userSettingsStore.update(userSettings);
            r(null);
          }).catch(err => {
            this.userSettingsStore.setError(err);
            re(err);
          });
        } else {
          r(null);
        }
      }).catch(e => {
        re(e);
      });
    });
  }
  updateUserSettings(id, currentUserSettings) {
    return this.httpClient.post(this.config.serverUrl + '/update/users/' + id, currentUserSettings).toPromise();
  }
  static {
    this.ɵfac = function BaseService_Factory(t) {
      return new (t || BaseService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject('persistStorage', 8), i0.ɵɵinject(NotifyService), i0.ɵɵinject(UserService), i0.ɵɵinject(UserSettingsQuery), i0.ɵɵinject(UserSettingsStore), i0.ɵɵinject(i2.MsalService), i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BaseService,
      factory: BaseService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: ['persistStorage']
    }]
  }, {
    type: NotifyService
  }, {
    type: UserService
  }, {
    type: UserSettingsQuery
  }, {
    type: UserSettingsStore
  }, {
    type: i2.MsalService
  }, {
    type: i1.HttpClient
  }], null);
})();
const log$a = new Logger("ChargebeeService");
class ChargebeeService {
  constructor(config, httpClient) {
    this.config = config;
    this.httpClient = httpClient;
  }
  /**
   * Load all chargebee products and plans
   */
  getProductInfo() {
    return this.httpClient.get(this.config.serverUrl + "/Chargebee_ProductInfos");
  }
  getSelfServiceUrl(workspaceId) {
    return this.httpClient.get(this.config.serverUrl + "/Chargebee_SelfServiceUrl?workspaceId=" + workspaceId);
  }
  getCheckoutUrl(workspaceId, planId) {
    return this.httpClient.get(this.config.serverUrl + "/Chargebee_CheckoutUrl?workspaceId=" + workspaceId + "&plan_id=" + planId);
  }
  static {
    this.ɵfac = function ChargebeeService_Factory(t) {
      return new (t || ChargebeeService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ChargebeeService,
      factory: ChargebeeService.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChargebeeService, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }], null);
})();
const log$9 = new Logger('ClientsService');
class ClientsService {
  constructor(config, httpClient, userSettingsStore, clientsStore) {
    this.config = config;
    this.httpClient = httpClient;
    this.userSettingsStore = userSettingsStore;
    this.clientsStore = clientsStore;
  }
  get adapter() {
    return this.httpClient;
  }
  get() {
    return this.httpClient.get(this.config.serverUrl + '/get/clients').pipe(tap({
      next: clients => this.clientsStore.set(clients),
      error: error => this.clientsStore.setError(error)
    }));
  }
  getById(id) {
    return firstValueFrom(this.httpClient.get(this.config.serverUrl + '/get/clients', {
      params: {
        $filter: `id eq ${id}`
      }
    })).then(p => {
      if (!p || p.length === 0) throw new Error('client.not-exist');
      return p[0];
    }).then(p => {
      this.clientsStore.upsert(p.id, p);
      return p;
    });
  }
  add(newClient) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'add_client'
    });
    return this.httpClient.post(this.config.serverUrl + '/add/clients', newClient).pipe(tap({
      next: client => this.clientsStore.add(client),
      error: error => this.clientsStore.setError(error)
    }));
  }
  update(client) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'update_client'
    });
    return this.httpClient.post(this.config.serverUrl + '/update/clients/' + client.id, client).pipe(tap({
      next: client => this.clientsStore.update(client.id, client),
      error: error => this.clientsStore.setError(error)
    }));
  }
  delete(client) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'delete_client'
    });
    return this.httpClient.delete(this.config.serverUrl + '/delete/clients/' + client.id).pipe(tap({
      next: () => this.clientsStore.remove(client.id),
      error: error => this.clientsStore.setError(error)
    }));
  }
  static {
    this.ɵfac = function ClientsService_Factory(t) {
      return new (t || ClientsService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(UserSettingsStore), i0.ɵɵinject(ClientsStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ClientsService,
      factory: ClientsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClientsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: UserSettingsStore
  }, {
    type: ClientsStore
  }], null);
})();
class ComegoService {
  constructor(config, httpClient, userSettingsStore, comegoStore) {
    this.config = config;
    this.httpClient = httpClient;
    this.userSettingsStore = userSettingsStore;
    this.comegoStore = comegoStore;
  }
  get adapter() {
    return this.httpClient;
  }
  async getBetween(start, end, userId) {
    const user = this.userSettingsStore.getValue();
    const times = await firstValueFrom(this.httpClient.get(this.config.serverUrl + `/get/comego?$filter=start ge '${start.toISOString()}' and start le '${end.toISOString()}'`.concat(userId ? ` and user__id eq '${userId}'` : '')));
    this.updateMyTimes(times);
    return times;
  }
  async getFromDate(date) {
    return await this.getBetween(startOfDay(date), endOfDay(date));
  }
  async add(...newEntities) {
    const timezone = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone ?? this.userSettingsStore.getValue()?.settings?.timeZone;
    const result = await firstValueFrom(this.httpClient.post(this.config.serverUrl + "/add/comego", newEntities?.map(({
      timeZone,
      ...x
    }) => ({
      ...x,
      timeZone: timeZone ?? timezone
    })))).then(x => Array.isArray(x) ? x : [x]).then(entries => {
      this.comegoStore.upsertMany(entries);
      return entries;
    }).catch(err => {
      this.comegoStore.setError(err);
      return Promise.reject(err);
    });
    return result;
  }
  async update(...entities) {
    const result = await firstValueFrom(this.httpClient.post(this.config.serverUrl + "/update/comego", entities)).then(x => Array.isArray(x) ? x : [x]).then(updatedEntities => {
      this.comegoStore.upsertMany(updatedEntities);
      return updatedEntities;
    }).catch(err => {
      this.comegoStore.setError(err);
      return Promise.reject(err);
    });
    return result;
  }
  async delete(...time) {
    const result = await firstValueFrom(this.httpClient.delete(this.config.serverUrl + "/delete/comego/" + time.map(x => x.id).join(";"))).then(x => Array.isArray(x) ? x : [x]).then(() => {
      this.comegoStore.remove(time.map(x => x.id));
      return time;
    }).catch(err => {
      this.comegoStore.setError(err);
      return Promise.reject(err);
    });
    return result;
  }
  updateMyTimes(res) {
    this.comegoStore.add(res.data);
    this.comegoStore.setLoading(false);
  }
  downloadExcelReport(filter, onUpdate) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.config.serverUrl + '/Create_Excel_Report_Sollstunden', filter, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events'
      }).subscribe({
        next: e => {
          if (e.type === HttpEventType.DownloadProgress) {
            onUpdate?.(e.loaded, e.total);
          } else if (e.type === HttpEventType.Response) {
            onUpdate?.(e.body.size, e.body.size);
            resolve(e.body);
          }
        },
        error: reject
      });
    });
  }
  downloadCsvReport(filter, onUpdate) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.config.serverUrl + '/Create_CSV_Report_Sollstunden', filter, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events'
      }).subscribe(e => {
        if (e.type === HttpEventType.DownloadProgress) {
          onUpdate?.(e.loaded, e.total);
        } else if (e.type === HttpEventType.Response) {
          onUpdate?.(e.body.size, e.body.size);
          resolve(e.body);
        }
      }, reject);
    });
  }
  static {
    this.ɵfac = function ComegoService_Factory(t) {
      return new (t || ComegoService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(UserSettingsStore), i0.ɵɵinject(ComegoStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ComegoService,
      factory: ComegoService.ɵfac,
      providedIn: 'root'
    });
  }
}
__decorate([transaction()], ComegoService.prototype, "updateMyTimes", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComegoService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: UserSettingsStore
  }, {
    type: ComegoStore
  }], {
    updateMyTimes: []
  });
})();
const log$8 = new Logger('FeedService');
class FeedService {
  constructor(config, httpClient, feedStore, userSettingsStore) {
    this.config = config;
    this.httpClient = httpClient;
    this.feedStore = feedStore;
    this.userSettingsStore = userSettingsStore;
  }
  async load(start, end) {
    //load not more then 6 months back
    let timestamp = new Date();
    timestamp.setDate(timestamp.getDate() - 180);
    if (timestamp > end) {
      throw 'Data older then 6 months can not be loaded';
    }
    this.feedStore.setLoading(true);
    return this.loadFeed(start, end).then(res => {
      this.updateFeed(res);
    });
  }
  updateFeed(res) {
    this.feedStore.set(res.data);
    this.feedStore.updateState({
      nextDate: res.nextDate
    });
    this.feedStore.setLoading(false);
  }
  loadFeed(start, end) {
    this.feedStore.set(null);
    return new Promise(async (resolve, reject) => {
      let to = new Date(end);
      to.setDate(to.getDate());
      to.setHours(24, 0, 0, 0);
      let from = new Date(start);
      from.setDate(from.getDate());
      from.setHours(0, 0, 0, 0);
      log$8.debug('load feed from ' + from.toLocaleString() + ' - ' + to.toLocaleString());
      const userSettings = this.userSettingsStore.getValue();
      let promises = [];
      if (!userSettings.enabledGraphScopes) {
        userSettings.enabledGraphScopes = [];
      }
      let enabledGraphScopes = [...userSettings.enabledGraphScopes];
      window.dataLayer.push({
        feed_your_feed: enabledGraphScopes.join('|')
      });
      if (enabledGraphScopes.includes(Scope.Calendar)) {
        promises.push(this.getCalendarEvents(from, to));
        if (userSettings.settings.additionalCalendarIds?.length > 0) {
          for (let index = 0; index < userSettings.settings.additionalCalendarIds.length; index++) {
            promises.push(this.getAdditionalCalendarEvents(from, to, userSettings.settings.additionalCalendarIds[index]));
          }
        }
      }
      if (enabledGraphScopes.includes(Scope.DevOpsOnline)) {
        promises.push(this.getDevOpsWorkRecentActivity(from, to));
      }
      if (enabledGraphScopes.includes(Scope.Groups)) {
        promises.push(this.getPlannerTasksCompletedInTimeRange(from, to));
      }
      if (enabledGraphScopes.includes(Scope.RecentFiles)) {
        promises.push(this.getRecentFiles(from, to));
      }
      if (enabledGraphScopes.includes(Scope.Sites)) {
        promises.push(this.getUsedFiles(from, to));
      }
      if (enabledGraphScopes.includes(Scope.Messages) && userSettings.settings.outlookMailFolderId) {
        promises.push(this.getSendedMailsInTimeRange(from, to, userSettings.settings.outlookMailFolderId));
      }
      if (enabledGraphScopes.includes(Scope.OutlookTasks)) {
        promises.push(this.getToDoTasksCompletedInTimeRange(from, to));
      }
      if (enabledGraphScopes.includes(Scope.TeamsCalls)) {
        promises.push(this.getCallFeedEvents(from, to, userSettings).toPromise());
      }
      if (userSettings.webex?.enabled) {
        if (userSettings.webex?.scopes?.find(x => x === "calls")) {
          promises.push(this.getWebexCalls(from, to));
        }
        if (userSettings.webex?.scopes?.find(x => x === "calendar")) {
          promises.push(this.getWebexCalendar(from, to));
        }
      }
      promises.push(this.getCustomFeedEvents(from, to).toPromise());
      promises.push(this.getFeedElementsBetwenRange(from, to).toPromise());
      promises.push(this.getMyTimesBetweenRangeWithOutlookReference(from, to).toPromise());
      const results = await Promise.all(promises.map(p => p.catch(e => {
        console.error(e);
        return new Error();
      })));
      const data = results.filter(result => !(result instanceof Error));
      if (data.length == 0) {
        resolve({
          data: []
        });
        return;
      }
      let retData = [];
      for (let index = 0; index < data.length - 2; index++) {
        let dataArray = data[index];
        if (dataArray) for (let index2 = 0; index2 < dataArray.length; index2++) {
          const element = dataArray[index2];
          if (element) retData = retData.concat(this.getElementsPerDay(element, from, to));
        }
      }
      retData = retData.filter(x => {
        if (new Date(x.start) > from && new Date(x.start) < to) return true;
        return false;
      });
      // merge feeds with calender (joinweburl)
      retData.forEach((element, index, arr) => {
        let match = arr.find((elem, elIdx) => elem.JoinWebUrl === element.link && index !== elIdx);
        if (match) {
          element.name = match.name;
          if (!element.refIds) element.refIds = [element.id];
          if (element.refIds.findIndex(x => x === match.id) === -1) element.refIds.push(match.id);
        }
        const matchedIds = element.refIds || [element.id];
        if (data[data.length - 1].findIndex(y => y.outlookCalenderReference && matchedIds.includes(y.outlookCalenderReference)) !== -1) element.booked = true;
        if (data[data.length - 2].findIndex(y => y.feedEventId && matchedIds.includes(y.feedEventId)) !== -1) element.hidden = true;
      });
      resolve({
        data: retData
      });
    });
  }
  getElementsPerDay(element, startFilter, endFilter) {
    let retVal = [];
    const start = new Date(element.start),
      end = new Date(element.end);
    const dates = this.getDatesBetween(start, end);
    dates.filter(({
      start,
      end
    }) => isValid(start) && isValid(end)).forEach((date, index) => {
      let clone = {
        ...element
      };
      clone.start = date.start.toISOString();
      clone.end = date.end.toISOString();
      clone.id = clone.id + '-' + index;
      if (new Date(date.end.getTime()) > startFilter && new Date(date.end.getTime()) < endFilter) retVal.push(clone);
    });
    return retVal;
  }
  getDatesBetween(startDate, endDate) {
    if (!(Math.abs(endDate.getTime() - startDate.getTime()) / 3600000 > 24)) {
      return [{
        start: startDate,
        end: endDate
      }];
    }
    let dates = [];
    dates.push({
      start: startDate,
      end: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 59)
    });
    let currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);
    let newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1);
    while (currentDate < newEndDate) {
      let pushEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
      dates.push({
        start: currentDate,
        end: pushEndDate
      });
      currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1 // Will increase month if over range
      );
    }
    let pushEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds(), endDate.getMilliseconds());
    if (pushEndDate.getHours() == 0 && pushEndDate.getMinutes() == 0 && pushEndDate.getSeconds() == 0 && pushEndDate.getMilliseconds() == 0) {
      pushEndDate.setHours(23, 59, 59);
    }
    dates.push({
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
      end: pushEndDate
    });
    console.log(dates);
    return dates;
  }
  getCustomFeedEvents(from, to) {
    return this.httpClient.get(this.config.serverUrl + "/get/customfeedevent?$filter=start ge '" + from.toISOString() + "' and start le '" + to.toISOString() + "'").pipe(map(events => {
      return events.map(event => {
        let retObj = {
          id: event.id,
          name: event.name,
          start: event.start,
          end: event.end,
          type: 'custom#' + event.category,
          icon: event.imageUrl ? '' : 'custom',
          iconUrl: event.imageUrl,
          booked: false,
          link: event.url,
          isAllDay: false,
          description: event.description,
          hidden: false,
          color: null
        };
        return retObj;
      });
    }));
  }
  getCallFeedEvents(from, to, userSettings) {
    const isDe = userSettings.settings.languageSetting.toLocaleLowerCase().indexOf('de') === 0;
    const anonymText = isDe ? 'Anonym' : 'anonymous';
    return this.httpClient.get(this.config.serverUrl + '/get_call_feed?from=' + from.toISOString() + '&to=' + to.toISOString()).pipe(map(events => {
      return events.map(event => {
        let eventName = isDe ? 'Telefonat mit ' : 'Call with ';
        let participants = [];
        let isSinglePersonMeeting = false;
        let organizer;
        if (organizer = event.organizer_v2 || event.organizer) if (event.organizer.user) {
          if (event.organizer.user.id !== userSettings.id) participants.push(event.organizer.user.displayName ? event.organizer.user.displayName : anonymText);
        } else if (event.organizer.acsUser) {
          if (event.organizer.acsUser.id !== userSettings.id) participants.push(event.organizer.acsUser.displayName ? event.organizer.acsUser.displayName : anonymText);
        } else if (event.organizer.spoolUser) {
          if (event.organizer.spoolUser.id !== userSettings.id) participants.push(event.organizer.spoolUser.displayName ? event.organizer.spoolUser.displayName : anonymText);
        } else if (event.organizer.phone) {
          if (event.organizer.phone.id !== userSettings.id) {
            participants.push(event.organizer.phone.displayName ? event.organizer.phone.displayName : anonymText);
          }
        } else if (event.organizer.guest) {
          if (event.organizer.guest.id !== userSettings.id) participants.push(event.organizer.guest.displayName ? event.organizer.guest.displayName : anonymText);
        }
        for (let index = 0; index < event.participants.length; index++) {
          const p = event.participants[index];
          if (p.user) {
            if (p.user.id !== userSettings.id) participants.push(p.user.displayName ? p.user.displayName : anonymText);
          } else if (p.acsUser) {
            if (p.acsUser.id !== userSettings.id) participants.push(p.acsUser.displayName ? p.acsUser.displayName : anonymText);
          } else if (p.spoolUser) {
            if (p.spoolUser.id !== userSettings.id) participants.push(p.spoolUser.displayName ? p.spoolUser.displayName : anonymText);
          } else if (p.phone) {
            if (p.phone.id !== userSettings.id) {
              participants.push(p.phone.displayName ? p.phone.displayName : anonymText);
            }
          } else if (p.guest) {
            if (p.guest.id !== userSettings.id) participants.push(p.guest.displayName ? p.guest.displayName : anonymText);
          }
        }
        participants = [...new Set(participants)];
        if (participants.length > 1) {
          if (event.participants.length - 1 == 1) {
            eventName += isDe ? ' einer Person' : ' one person';
          } else {
            eventName += event.participants.length - 1 + (isDe ? ' Personen' : ' people');
          }
        } else if (participants.length == 1) {
          eventName += participants[0];
        } else {
          if (event.startDateTime === event.endDateTime) return null;
          eventName = isDe ? "Meeting" : "Meeting";
          isSinglePersonMeeting = true;
        }
        let retObj = {
          id: event.id,
          name: eventName,
          start: event.startDateTime,
          end: event.endDateTime,
          type: 'Call',
          icon: '',
          iconUrl: 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/teams_48x1_5.png',
          booked: false,
          link: event.joinWebUrl,
          isAllDay: false,
          isSinglePersonMeeting,
          description: participants.length < 2 ? '' : (isDe ? 'Du und ' : 'You and ') + participants.join(', '),
          hidden: false,
          color: null
        };
        return retObj;
      });
    }));
  }
  getMyTimesBetweenRangeWithOutlookReference(from, to) {
    const userSettings = this.userSettingsStore.getValue();
    return this.httpClient.get(this.config.serverUrl + "/get/times?$filter=start ge '" + from.toISOString() + "' and start le '" + to.toISOString() + "' and user__id eq '" + userSettings.id + "' and outlookCalenderReference ne ''");
  }
  getFeedElementsBetwenRange(from, to) {
    const userSettings = this.userSettingsStore.getValue();
    return this.httpClient.get(this.config.serverUrl + "/get/feedelement?$filter=start ge '" + from.toISOString() + "' and start le '" + to.toISOString() + "' and hideInFeed eq true");
  }
  /**
   * Get the current user calendarview for a specific time range.
   */
  getCalendarEvents(start, end) {
    var url = 'https://graph.microsoft.com/v1.0/me/calendarview?startdatetime=' + start.toISOString() + '&enddatetime=' + end.toISOString();
    return this.runGraphApiRequestAndFillStore(url, events => {
      return events.map(event => {
        if (event.showAs == 'free' || event.showAs == 'workingElsewhere') return null;
        const startDate = new Date(event.start.dateTime);
        const endDate = new Date(event.end.dateTime);
        let retObj = {
          id: event.id,
          name: event.subject,
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          link: event.webLink,
          type: 'calendar',
          icon: '',
          description: event.bodyPreview,
          iconUrl: window.location.origin + '/assets/img/logos/calendar_logo.png',
          booked: false,
          isAllDay: false,
          hidden: false,
          JoinWebUrl: event.onlineMeeting?.joinUrl,
          color: null
        };
        return retObj;
      });
    }, start, end);
  }
  getWebexCalendar(start, end) {
    return this.runWebexApiRequest(`https://webexapis.com/v1/meetings?from=${start.toISOString()}&to=${end.toISOString()}`).then(response => {
      const meetings = response.items;
      return meetings.map(x => {
        let retObj = {
          id: x.id,
          name: x.title,
          start: x.start,
          end: x.end,
          type: 'Call',
          icon: '',
          iconUrl: location.origin + "/assets/svg/icons/webex.svg",
          booked: false,
          isAllDay: false,
          hidden: false,
          link: null,
          color: null
        };
        return retObj;
      });
    });
  }
  getWebexCalls(start, end) {
    return firstValueFrom(this.httpClient.get(this.config.serverUrl + '/get_call_feed_webex?from=' + start.toISOString() + '&to=' + end.toISOString())).then(events => {
      return events.map(x => {
        let retObj = {
          id: x.id,
          name: x.name,
          start: x.startDateTime,
          end: x.endDateTime,
          type: 'Call',
          icon: '',
          iconUrl: location.origin + "/assets/svg/icons/webex.svg",
          booked: false,
          isAllDay: false,
          hidden: false,
          link: null,
          color: null
        };
        return retObj;
      });
    });
  }
  /**
   * Get the calendar for a specific time range and calendar id.
   */
  getAdditionalCalendarEvents(start, end, calendar) {
    var url = 'https://graph.microsoft.com/v1.0/me/calendars/' + calendar.id + '/calendarview?startdatetime=' + start.toISOString() + '&enddatetime=' + end.toISOString();
    return this.runGraphApiRequestAndFillStore(url, events => {
      return events.map(event => {
        if (event.showAs == 'free' || event.showAs == 'workingElsewhere') return null;
        if (event.originalStartTimeZone.indexOf('tzone://') != -1) {
          event.originalStartTimeZone = 'UTC';
        }
        if (event.originalEndTimeZone.indexOf('tzone://') != -1) {
          event.originalEndTimeZone = 'UTC';
        }
        const startDate = new Date(event.start.dateTime);
        const endDate = new Date(event.end.dateTime);
        let retObj = {
          id: event.id,
          name: event.subject,
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          link: event.webLink,
          type: 'calendar',
          calendar: {
            name: calendar.name,
            color: calendar.hexColor
          },
          icon: '',
          color: calendar.hexColor,
          description: event.bodyPreview,
          iconUrl: window.location.origin + '/assets/img/logos/calendar_logo.png',
          booked: false,
          isAllDay: false,
          hidden: false
        };
        return retObj;
      });
    }, start, end);
  }
  getRecentFiles(start, end) {
    var url = 'https://graph.microsoft.com/v1.0/me/drive/recent?$orderby=lastModifiedDateTime desc';
    const user = this.userSettingsStore.getValue();
    return this.runGraphApiRequestAndFillStore(url, events => {
      return events.filter(Boolean).filter(({
        lastModifiedBy
      }) => {
        const email = lastModifiedBy?.user?.email?.toLowerCase();
        return email && email === user.email?.toLowerCase();
      }).map(file => {
        let retObj;
        retObj = {
          id: file.id,
          name: file.name,
          start: new Date(file.createdDateTime).toDateString() == new Date(file.lastModifiedDateTime).toDateString() ? file.createdDateTime : file.lastModifiedDateTime,
          end: file.lastModifiedDateTime,
          link: file.webUrl,
          type: 'file',
          icon: file.name.substring(file.name.lastIndexOf('.') + 1),
          iconUrl: this.getFileLogoUrl(file.name.substring(file.name.lastIndexOf('.') + 1)),
          booked: false,
          isAllDay: false,
          hidden: false,
          color: null
        };
        return retObj;
      });
    }, start, end, (d, start, end) => {
      let allFilesInDateRange = true;
      for (let index = 0; index < d.length; index++) {
        const element = d[index];
        if (element && new Date(element.end) < end) {
          allFilesInDateRange = false;
          break;
        }
      }
      return allFilesInDateRange;
    });
  }
  getUsedFiles(start, end) {
    var url = 'https://graph.microsoft.com/beta/me/insights/used';
    return this.runGraphApiRequestAndFillStore(url, events => {
      return events.map(file => {
        let retObj;
        retObj = {
          id: file.id,
          name: file.resourceVisualization.title,
          description: file.resourceVisualization.previewText,
          start: file.lastUsed.lastModifiedDateTime,
          end: file.lastUsed.lastModifiedDateTime,
          link: file.resourceReference.webUrl,
          type: 'spfile',
          icon: file.resourceReference.webUrl.substring(file.resourceReference.webUrl.lastIndexOf('.') + 1),
          iconUrl: this.getFileLogoUrl(file.resourceReference.webUrl.substring(file.resourceReference.webUrl.lastIndexOf('.') + 1)),
          booked: false,
          isAllDay: false,
          hidden: false,
          color: null
        };
        return retObj;
      });
    }, start, end, (d, start, end) => {
      let allFilesInDateRange = true;
      for (let index = 0; index < d.length; index++) {
        const element = d[index];
        if (element && new Date(element.end) < end) {
          allFilesInDateRange = false;
          break;
        }
      }
      return allFilesInDateRange;
    });
  }
  getFileLogoUrl(fileExtension) {
    if (fileExtension.toLocaleLowerCase().startsWith('xls')) {
      return 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/excel_48x1.png';
    }
    if (fileExtension.toLocaleLowerCase().startsWith('doc')) {
      return 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/word_48x1.png';
    }
    if (fileExtension.toLocaleLowerCase().startsWith('ppt')) {
      return 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/powerpoint_48x1_5.png';
    }
    if (fileExtension.toLocaleLowerCase().startsWith('ppt')) {
      return 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/powerpoint_48x1_5.png';
    }
    if (fileExtension.toLocaleLowerCase().startsWith('vsd')) {
      return 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/visio_48x1_5.png';
    }
    if (fileExtension.toLocaleLowerCase().startsWith('vsd')) {
      return 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/visio_48x1_5.png';
    }
    if (fileExtension.toLocaleLowerCase().startsWith('pdf')) {
      return 'https://spoprod-a.akamaihd.net/files/fabric/assets/item-types-fluent/32/pdf.svg?v6';
    }
    return 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/onedrive_48x2.png';
  }
  getPlannerTasksCompletedInTimeRange(start, end) {
    const userSettings = this.userSettingsStore.getValue();
    return this.runGraphApiRequestAndFillStore('https://graph.microsoft.com/v1.0/me/planner/tasks', events => {
      return events.map(plannerTask => {
        let retObj;
        if (plannerTask.completedBy?.user.id == userSettings.id && plannerTask.completedDateTime != null && new Date(plannerTask.completedDateTime) >= start && new Date(plannerTask.completedDateTime) <= end) {
          retObj = {
            id: plannerTask.id,
            name: plannerTask.title,
            start: plannerTask.completedDateTime,
            end: plannerTask.completedDateTime,
            link: 'https://tasks.office.com/Home/Planner#/plantaskboard?planId=' + plannerTask.planId + '&taskId=' + plannerTask.id,
            type: 'plannerTask',
            icon: '',
            iconUrl: window.location.origin + '/assets/img/logos/planer_logo.png',
            booked: false,
            isAllDay: false,
            hidden: false,
            color: null
          };
        }
        return retObj;
      });
    }, start, end);
  }
  getToDoTasksCompletedInTimeRange(start, end) {
    return this.runGraphApiRequestAndFillStore("https://graph.microsoft.com/beta/me/outlook/tasks?$filter=completedDateTime/dateTime ge '" + start.toISOString() + "' and completedDateTime/dateTime le '" + end.toISOString() + "'&$select=completedDateTime,subject,createdDateTime,recurrence", events => {
      return events.map(file => {
        let retObj = {
          id: file.id,
          name: file.subject,
          start: file.createdDateTime,
          end: file.createdDateTime,
          link: 'https://to-do.office.com/tasks/id/' + file.id + '/details',
          type: 'todoTask',
          icon: '',
          iconUrl: window.location.origin + '/assets/img/logos/todo_logo.png',
          booked: false,
          isAllDay: false,
          hidden: false,
          color: null
        };
        return retObj;
      });
    }, start, end);
  }
  getSendedMailsInTimeRange(start, end, mailFolderId) {
    return this.runGraphApiRequestAndFillStore('https://graph.microsoft.com/v1.0/me/mailFolders/' + mailFolderId + '/messages?$filter=receivedDateTime ge ' + start.toISOString() + ' and receivedDateTime le ' + end.toISOString() + '&$select=sentDateTime,id,subject,bodyPreview,toRecipients,ccRecipients,webLink', events => {
      return events.map(mail => {
        let retObj;
        if (mail['@odata.type'] != '#microsoft.graph.eventMessage') retObj = {
          id: mail.id,
          name: mail.subject,
          start: mail.sentDateTime,
          end: mail.sentDateTime,
          link: mail.webLink,
          type: 'mail',
          icon: '',
          description: mail.bodyPreview,
          iconUrl: 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/outlook_48x1.png',
          booked: false,
          isAllDay: false,
          hidden: false,
          color: null
        };
        return retObj;
      });
    }, start, end);
  }
  getDevOpsWorkRecentActivity(start, end) {
    return new Promise((resolve, reject) => {
      this.httpClient.get('https://app.vssps.visualstudio.com/_apis/accounts').subscribe(accounts => {
        let promises = [];
        for (let index = 0; index < accounts.length; index++) {
          const account = accounts[index];
          promises.push(firstValueFrom(this.httpClient.get('https://dev.azure.com/' + account.AccountName + '/_apis/work/accountmyworkrecentactivity?api-version=6.0-preview.2')).then(data => {
            data = data.value.filter(file => {
              return file && new Date(file.changedDate) >= start && new Date(file.changedDate) <= end;
            }).map(file => {
              return {
                id: file.id,
                name: file.workItemType + ' ' + file.id + ': ' + file.title,
                start: file.changedDate,
                end: file.changedDate,
                link: 'https://dev.azure.com/' + account.AccountName + '/' + file.teamProject + '/_workitems/edit/' + file.id,
                type: 'devops',
                icon: '',
                iconUrl: 'https://cdn.vsassets.io/ext/ms.vss-work-web/common-content/Content/Nav-Plan.XB8qU6v7kvBk_Tcy.png',
                booked: false,
                isAllDay: false,
                description: file.teamProject + ': ' + file.workItemType + ' i18n:devops.' + file.activityType,
                hidden: false,
                color: null
              };
            });
            return data;
          }));
        }
        Promise.all(promises).then(data => {
          let retVal = [];
          for (let index = 0; index < data.length; index++) {
            const elements = data[index];
            retVal = retVal.concat(elements);
          }
          resolve(retVal);
        }).catch(error => reject(error));
      }, error => reject(error));
    });
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  toggleHideEntryInFeed(entry) {
    if (entry.hidden) {
      return new Promise((resolve, reject) => {
        this.httpClient.get(this.config.serverUrl + "/get/feedelement?$filter=feedEventId eq '" + entry.id + "'").subscribe(x => {
          this.httpClient.delete(this.config.serverUrl + '/delete/feedelement/' + x[0].id).subscribe(() => {
            let newEtry = {
              ...entry
            };
            newEtry.hidden = !newEtry.hidden;
            this.feedStore.update(entry.id, newEtry);
            resolve(null);
          }, error => {
            this.feedStore.setError(error);
            reject();
          });
        }, error => {
          this.feedStore.setError(error);
          reject();
        });
      });
    } else {
      return this.httpClient.post(this.config.serverUrl + '/add/feedelement', {
        feedEventId: entry.id,
        start: entry.start,
        end: entry.end,
        hideInFeed: !entry.hidden
      }).pipe(tap(() => {
        let newEtry = {
          ...entry
        };
        newEtry.hidden = !newEtry.hidden;
        this.feedStore.update(entry.id, newEtry);
      }, error => this.feedStore.setError(error))).toPromise();
    }
  }
  runGraphApiRequestAndFillStore(url, mapObject, start, end, additionalStopCondition) {
    return new Promise(async (resolve, reject) => {
      let runWhile = true;
      let data = [];
      let options = {};
      if (url.indexOf('calendarview') != -1) {
        let timezone = this.userSettingsStore.getValue().settings.timeZone;
        if (!timezone) timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        options = {
          headers: new HttpHeaders({
            Prefer: 'outlook.timezone="' + timezone + '"'
          })
        };
      }
      while (runWhile) {
        try {
          var retData = await this.httpClient.get(url, options).toPromise();
          data = data.concat(mapObject(retData.value));
          if (retData['@odata.nextLink'] && retData['@odata.nextLink'] != url && (!additionalStopCondition || additionalStopCondition(data, start, end))) {
            url = retData['@odata.nextLink'];
          } else {
            runWhile = false;
            resolve(data.filter(x => x != null));
          }
        } catch (error) {
          if (error.status == 429) {
            let retry = error.headers.get('Retry-After');
            if (!retry) retry = 1;
            await new Promise(resolve => setTimeout(resolve, retry * 1000));
          } else {
            runWhile = false;
            reject(error);
          }
        }
      }
    });
  }
  runWebexApiRequest(url) {
    let accessToken = this.userSettingsStore.getValue().webex.tokens.access_token;
    return firstValueFrom(this.httpClient.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    }));
  }
  static {
    this.ɵfac = function FeedService_Factory(t) {
      return new (t || FeedService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(FeedStore), i0.ɵɵinject(UserSettingsStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FeedService,
      factory: FeedService.ɵfac,
      providedIn: 'root'
    });
  }
}
__decorate([transaction()], FeedService.prototype, "updateFeed", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FeedService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: FeedStore
  }, {
    type: UserSettingsStore
  }], {
    updateFeed: []
  });
})();
const log$7 = new Logger("FreshdeskService");
class FreshdeskService {
  constructor(config, httpClient) {
    this.config = config;
    this.httpClient = httpClient;
  }
  add(ticket) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'create_freshdesk_ticket'
    });
    return this.httpClient.post(this.config.serverUrl + "/freshdesk-create-ticket", ticket);
  }
  static {
    this.ɵfac = function FreshdeskService_Factory(t) {
      return new (t || FreshdeskService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FreshdeskService,
      factory: FreshdeskService.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FreshdeskService, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }], null);
})();
const log$6 = new Logger('MyTimesService');
class MyTimesService {
  constructor(config, httpClient, myTimesStore, myTimesQuery, userSettingsStore, feedStore) {
    this.config = config;
    this.httpClient = httpClient;
    this.myTimesStore = myTimesStore;
    this.myTimesQuery = myTimesQuery;
    this.userSettingsStore = userSettingsStore;
    this.feedStore = feedStore;
  }
  get adapter() {
    return this.httpClient;
  }
  get(nextDate) {
    if (this.myTimesStore.getValue().loading) return;
    if (!this.myTimesQuery.getHasMore()) return;
    this.myTimesStore.setLoading(true);
    this.myTimesStore.setError(null);
    this.loadMyTimes(nextDate).then(res => this.updateMyTimes(res));
  }
  async getBetween(start, end) {
    const user = this.userSettingsStore.getValue();
    const times = await firstValueFrom(this.httpClient.get(this.config.serverUrl + "/get/times?$filter=start ge '" + start.toISOString() + "' and start le '" + end.toISOString() + "' and user__id eq '" + user.id + "'"));
    this.updateMyTimes(times);
    return times;
  }
  updateMyTimes(res) {
    const user = this.userSettingsStore.getValue();
    if (res.wasInit) this.myTimesStore.remove(x => !!x.end || x.user?.id !== this.userSettingsStore.getValue().id || !x.workspace?.id || x.workspace.id !== user.workspace?.id); // if init remove all valid times except running times
    this.myTimesStore.add(res.data);
    if (res.nextDate) this.myTimesStore.updateState({
      nextDate: res.nextDate
    });
    this.myTimesStore.setLoading(false);
  }
  loadMyTimes(nextDate) {
    return new Promise(async (resolve, reject) => {
      let pageSize = 7;
      let wasInit = false;
      if (nextDate == 'init') {
        wasInit = true;
        //damit auch zukünftige Zeiten angezeigt werden
        var toDate = await this.findNextTimeOfUser(new Date(new Date().setFullYear(new Date().getFullYear() + 50)).toISOString()).toPromise();
        if (toDate.length == 0) {
          resolve({
            data: [],
            nextDate: null,
            wasInit
          });
          return;
        }
        if (toDate[0].end === null) {
          toDate[0].end = toDate[0].start;
        }
        let to = new Date(toDate[0].end);
        to.setDate(to.getDate() + 1);
        to.setHours(0, 0, 0, 0);
        nextDate = to.toISOString();
      }
      let from = new Date(nextDate);
      from.setDate(from.getDate() - pageSize);
      from.setHours(0, 0, 0, 0);
      log$6.debug('load myTimes from ' + from.toISOString() + ' - ' + nextDate);
      var promises = [];
      promises.push(this.httpClient.get(this.config.serverUrl + "/get/times?$filter=start ge '" + from.toISOString() + "' and start le '" + nextDate + "' and user__id eq '" + this.userSettingsStore.getValue().id + "'").toPromise());
      promises.push(this.findNextTimeOfUser(from.toISOString()).toPromise());
      Promise.all(promises).then(data => {
        resolve({
          data: data[0],
          nextDate: data[1].length > 0 ? data[1][0].end : null,
          wasInit
        });
      }).catch(error => reject(error));
    });
  }
  findNextTimeOfUser(nextDate) {
    return this.httpClient.get(this.config.serverUrl + "/get/times?$skip=0&$orderby=start desc&$top=1&$filter=start le '" + nextDate + "' and user__id eq '" + this.userSettingsStore.getValue().id + "'");
  }
  getLatestRecordings(take, type) {
    return firstValueFrom(this.httpClient.get(this.config.serverUrl + '/get/times', {
      params: {
        $skip: 0,
        $orderby: 'start desc',
        ...(~~take === 0 ? {} : {
          $top: take
        }),
        $filter: `user__id eq '${this.userSettingsStore.getValue().id}' and end eq null`
      }
    }));
  }
  add(...newTimes) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'add_time'
    });
    for (let index = 0; index < newTimes.length; index++) {
      const time = newTimes[index];
      time.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    const user = this.userSettingsStore.getValue();
    return this.httpClient.post(this.config.serverUrl + '/add/times', newTimes).pipe(map(times => Array.isArray(times) ? times : [times]), tap(times => {
      const userTimes = user?.id ? times.filter(x => x.user.id === user.id) : times;
      this.updateBooked(...userTimes);
      return user?.id && this.myTimesStore.add(userTimes);
    }, error => this.myTimesStore.setError(error)));
  }
  update(...times) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'update_time'
    });
    const user = this.userSettingsStore.getValue();
    return this.httpClient.post(this.config.serverUrl + '/update/times', times).pipe(map(_times => Array.isArray(_times) ? _times : [_times]), tap(_times => {
      if (user?.id) this.myTimesStore.upsertMany(_times.filter(x => x.user?.id && x.user.id === user.id));
    }, error => this.myTimesStore.setError(error)));
  }
  delete(...times) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'delete_Time'
    });
    return this.httpClient.delete(this.config.serverUrl + '/delete/times/' + times.map(x => x.id).join(';')).pipe(map(times => Array.isArray(times) ? times : [times]), tap(() => {
      this.myTimesStore.remove(times.map(x => x.id));
    }, error => this.myTimesStore.setError(error)));
  }
  addTag(tag, ...times) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'add_time_tag'
    });
    for (let index = 0; index < times.length; index++) {
      times[index] = produce(times[index], draft => {
        if (!this.inArray(draft.tags, function (e) {
          return e.id === tag.id;
        })) {
          draft.tags.push(tag);
        }
      });
    }
    return this.httpClient.post(this.config.serverUrl + '/update/times', times).pipe(tap(time => this.myTimesStore.update(time.id, time), error => this.myTimesStore.setError(error)));
  }
  removeTag(tag, ...times) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'remove_time_tag'
    });
    if (!Array.isArray(times)) times = [times];
    for (let index = 0; index < times.length; index++) {
      times[index] = produce(times[index], draft => {
        for (let i = 0; i < draft.tags.length; i++) {
          var obj = draft.tags[i];
          if (tag.id == obj.id) {
            draft.tags.splice(i, 1);
          }
        }
      });
    }
    return this.httpClient.post(this.config.serverUrl + '/update/times', times).pipe(tap(time => this.myTimesStore.update(time.id, time), error => this.myTimesStore.setError(error)));
  }
  inArray(array, comparer) {
    for (let i = 0; i < array.length; i++) {
      if (comparer(array[i])) return true;
    }
    return false;
  }
  updateBooked(...times) {
    const ids = this.feedStore.getValue().ids;
    const feedItems = times.filter(x => !!x.outlookCalenderReference).map(x => x.outlookCalenderReference).filter(ref => ids.findIndex(x => x === ref) !== -1);
    if (feedItems.length > 0) return this.feedStore.update(feedItems, {
      booked: true
    });
    return null;
  }
  static {
    this.ɵfac = function MyTimesService_Factory(t) {
      return new (t || MyTimesService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(MyTimesStore), i0.ɵɵinject(MyTimesQuery), i0.ɵɵinject(UserSettingsStore), i0.ɵɵinject(FeedStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MyTimesService,
      factory: MyTimesService.ɵfac,
      providedIn: 'root'
    });
  }
}
__decorate([transaction()], MyTimesService.prototype, "updateMyTimes", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MyTimesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: MyTimesStore
  }, {
    type: MyTimesQuery
  }, {
    type: UserSettingsStore
  }, {
    type: FeedStore
  }], {
    updateMyTimes: []
  });
})();
class PaddleService {
  constructor(config, httpClient) {
    this.config = config;
    this.httpClient = httpClient;
  }
  getSelfServiceUrl(workspaceId) {
    return this.httpClient.get(this.config.serverUrl + "/Subscription_SelfServiceUrl?workspaceId=" + workspaceId);
  }
  updateQuantity(workspaceId, quantity) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'update_plan_quantity'
    });
    return this.httpClient.get(this.config.serverUrl + "/Subscription_UpdateQuantity?workspaceId=" + workspaceId + "&quantity=" + quantity);
  }
  changePlan(workspaceId) {
    return this.httpClient.get(this.config.serverUrl + "/Paddle_ChangePlan?workspaceId=" + workspaceId);
  }
  getPaymentHistory(workspaceId, from, to) {
    return this.httpClient.get(this.config.serverUrl + "/Paddle_PaymentHistory?workspaceId=" + workspaceId + "&from=" + from + "&to=" + to);
  }
  getPrices() {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'get_prices'
    });
    return this.httpClient.jsonp(this.config.production ? "https://checkout.paddle.com/api/2.0/prices?product_ids=903167,903165" : "https://sandbox-checkout.paddle.com/api/2.0/prices?product_ids=73094,73093", "callback");
  }
  static {
    this.ɵfac = function PaddleService_Factory(t) {
      return new (t || PaddleService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PaddleService,
      factory: PaddleService.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PaddleService, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }], null);
})();
let ProjectsQuery = class ProjectsQuery extends QueryEntity {
  constructor(store) {
    super(store);
    this.store = store;
  }
  static {
    this.ɵfac = function ProjectsQuery_Factory(t) {
      return new (t || ProjectsQuery)(i0.ɵɵinject(ProjectsStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ProjectsQuery,
      factory: ProjectsQuery.ɵfac,
      providedIn: "root"
    });
  }
};
ProjectsQuery = __decorate([QueryConfig({
  sortBy: "name",
  sortByOrder: Order.ASC
})], ProjectsQuery);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProjectsQuery, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: ProjectsStore
  }], null);
})();
const log$5 = new Logger('ProjectService');
class ProjectsService {
  constructor(config, httpClient, projectsStore, projectsQuery, userSettingsStore) {
    this.config = config;
    this.httpClient = httpClient;
    this.projectsStore = projectsStore;
    this.projectsQuery = projectsQuery;
    this.userSettingsStore = userSettingsStore;
  }
  removeUnknown() {
    return this.projectsStore.remove(x => !x?.workspace || x.workspace.id === this.userSettingsStore.getValue()?.workspace?.id);
  }
  get adapter() {
    return this.httpClient;
  }
  get(loadCompletedProjects = false) {
    return this.httpClient.get(this.config.serverUrl + '/get/projects' + (loadCompletedProjects ? '' : '?$filter=completed eq false or completed eq null')).pipe(tap(projects => {
      this.removeUnknown();
      this.projectsStore.upsertMany(projects);
    }, error => this.projectsStore.setError(error)));
  }
  getById(id) {
    return this.httpClient.get(this.config.serverUrl + '/get/projects', {
      params: {
        $filter: `id eq ${id}`
      }
    }).toPromise().then(p => {
      if (!p || p.length === 0) throw new Error('project.not-exist');
      return p[0];
    }).then(p => {
      this.projectsStore.upsert(p.id, p);
      return p;
    });
  }
  add(newProject) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'add_project'
    });
    return this.httpClient.post(this.config.serverUrl + '/add/projects', newProject).pipe(tap(project => this.projectsStore.add(project), error => this.projectsStore.setError(error)));
  }
  update(project) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'update_project'
    });
    return this.httpClient.post(this.config.serverUrl + '/update/projects/' + project.id, project).pipe(tap(project => this.projectsStore.update(project.id, project), error => this.projectsStore.setError(error)));
  }
  delete(project) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'delete_project'
    });
    return this.httpClient.delete(this.config.serverUrl + '/delete/projects/' + project.id).pipe(tap(() => this.projectsStore.remove(project.id), error => this.projectsStore.setError(error)));
  }
  findByTaskName(name, userId) {
    return this.httpClient.get(this.config.serverUrl + '/get/times', {
      params: {
        $filter: `name eq '${name.replace("'", '')}' and project__completed eq false and task__completed eq false`.concat(userId ? ` and user__id eq '${userId}'` : ''),
        $orderby: 'start desc'
      }
    }).toPromise().then(t => {
      return t.reduce((retVal, time) => {
        retVal.push({
          project: {
            id: time.project.id,
            name: time.project.name
          },
          task: time.task ? {
            id: time.task.id,
            name: time.task.name
          } : null
        });
        return retVal;
      }, []);
    });
  }
  static {
    this.ɵfac = function ProjectsService_Factory(t) {
      return new (t || ProjectsService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(ProjectsStore), i0.ɵɵinject(ProjectsQuery), i0.ɵɵinject(UserSettingsStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ProjectsService,
      factory: ProjectsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProjectsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: ProjectsStore
  }, {
    type: ProjectsQuery
  }, {
    type: UserSettingsStore
  }], null);
})();
const log$4 = new Logger('SearchService');
class SearchService {
  constructor(config, httpClient) {
    this.config = config;
    this.httpClient = httpClient;
  }
  searchTasksAndProjects(searchString, filterByProject, filterByClient) {
    return this.httpClient.post(this.config.serverUrl + '/search', {
      searchString: searchString,
      projectId: filterByProject ? filterByProject.id : null,
      clientId: filterByClient ? filterByClient.id : null
    });
  }
  getProjectsTasksAndTagsInTimeRange(from, to) {
    return new Promise((resolve, reject) => {
      let projects = [];
      let tasks = [];
      let tags = [];
      this.httpClient.get(this.config.serverUrl + "/get/times?$filter=start ge '" + from.toISOString() + "' and start le '" + to.toISOString() + "' and end ne null").subscribe(times => {
        times?.forEach(time => {
          if (time.task?.id && !tasks.find(x => x.id === time.task.id)) {
            tasks.push({
              ...time.task,
              ...(time.project ? {
                project: {
                  id: time.project.id,
                  name: time.project.name
                }
              } : {})
            });
          }
          if (time.project?.id && !projects.find(x => x.id === time.project.id)) {
            projects.push(time.project);
            if (time.project.tags?.length > 0) {
              var foundTags = time.project.tags.map(x => {
                return {
                  name: x.name,
                  id: x.id,
                  tagType: 0
                };
              });
              tags.push(...foundTags.filter(x => tags.findIndex(t => t.id === x.id) === -1));
            }
          }
          if (time.tags?.length > 0) {
            let foundTags = time.tags.map(x => {
              return {
                name: x.name,
                id: x.id,
                tagType: 1
              };
            });
            tags.push(...foundTags.filter(x => tags.findIndex(t => t.id === x.id) === -1));
          }
        });
        resolve({
          projects,
          tasks,
          tags
        });
      });
    });
  }
  static {
    this.ɵfac = function SearchService_Factory(t) {
      return new (t || SearchService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SearchService,
      factory: SearchService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SearchService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }], null);
})();
const log$3 = new Logger("TagsService");
class TagsService {
  constructor(config, httpClient, userSettingsStore, tagsStore) {
    this.config = config;
    this.httpClient = httpClient;
    this.userSettingsStore = userSettingsStore;
    this.tagsStore = tagsStore;
  }
  get adapter() {
    return this.httpClient;
  }
  get() {
    return this.httpClient.get(this.config.serverUrl + "/get/tags").pipe(tap(tags => this.tagsStore.set(tags), error => this.tagsStore.setError(error)));
  }
  add(newTag) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'add_tag'
    });
    return this.httpClient.post(this.config.serverUrl + "/add/tags", newTag).pipe(tap(tag => this.tagsStore.add(tag), error => this.tagsStore.setError(error)));
  }
  update(tag) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'update_tag'
    });
    return this.httpClient.post(this.config.serverUrl + "/update/tags/" + tag.id, tag).pipe(tap(tag => this.tagsStore.update(tag.id, tag), error => this.tagsStore.setError(error)));
  }
  delete(tag) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'delete_tag'
    });
    return this.httpClient.delete(this.config.serverUrl + "/delete/tags/" + tag.id).pipe(tap(() => this.tagsStore.remove(tag.id), error => this.tagsStore.setError(error)));
  }
  static {
    this.ɵfac = function TagsService_Factory(t) {
      return new (t || TagsService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(UserSettingsStore), i0.ɵɵinject(TagsStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TagsService,
      factory: TagsService.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TagsService, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: UserSettingsStore
  }, {
    type: TagsStore
  }], null);
})();
const log$2 = new Logger('TasksService');
class TasksService {
  constructor(config, httpClient, tasksStore) {
    this.config = config;
    this.httpClient = httpClient;
    this.tasksStore = tasksStore;
  }
  get adapter() {
    return this.httpClient;
  }
  getByProject(project, showCompletedTasks) {
    let filter = "$filter=project__id eq '" + project.id + "'";
    if (!showCompletedTasks) {
      filter += ' and completed eq false';
    }
    return this.httpClient.get(this.config.serverUrl + '/get/tasks?' + filter).pipe(tap(tasks => this.tasksStore.upsertMany(tasks), error => this.tasksStore.setError(error)));
  }
  getById(id) {
    return this.httpClient.get(this.config.serverUrl + '/get/tasks/' + id).pipe(map(response => {
      return response;
    })).pipe(tap(task => this.tasksStore.upsert(task.id, task), error => this.tasksStore.setError(error)));
  }
  add(newTask) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'add_task'
    });
    return this.httpClient.post(this.config.serverUrl + '/add/tasks', newTask).pipe(tap(task => this.tasksStore.add(task), error => this.tasksStore.setError(error)));
  }
  update(task) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'update_task'
    });
    return this.httpClient.post(this.config.serverUrl + '/update/tasks/' + task.id, task).pipe(tap(task => this.tasksStore.update(task.id, task), error => this.tasksStore.setError(error)));
  }
  delete(task) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'delete_task'
    });
    return this.httpClient.delete(this.config.serverUrl + '/delete/tasks/' + task.id).pipe(tap(() => this.tasksStore.remove(task.id), error => this.tasksStore.setError(error)));
  }
  static {
    this.ɵfac = function TasksService_Factory(t) {
      return new (t || TasksService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(TasksStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TasksService,
      factory: TasksService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TasksService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: TasksStore
  }], null);
})();
class TimesService {
  constructor(config, httpClient, userSettingsStore) {
    this.config = config;
    this.httpClient = httpClient;
    this.userSettingsStore = userSettingsStore;
  }
  get adapter() {
    return this.httpClient;
  }
  getByProject(project) {
    return this.httpClient.get(this.config.serverUrl + "/get/times?$filter=project__id eq '" + project.id + "' and end ne null");
  }
  getFirstProjectTime(projectId) {
    return this.httpClient.get(this.config.serverUrl + "/get/times?$filter=project__id eq '" + projectId + "' and start ne null and end ne null&$orderby=start asc&$top=1").toPromise().then(x => x?.[0]);
  }
  getLastProjectTime(projectId) {
    return this.httpClient.get(this.config.serverUrl + "/get/times?$filter=project__id eq '" + projectId + "' and start ne null and end ne null&$orderby=start desc&$top=1").toPromise().then(x => x?.[0]);
  }
  getByProjectBetweenRange(project, from, to) {
    return this.httpClient.get(this.config.serverUrl + "/get/times?$filter=project__id eq '" + project.id + "' and start ge '" + from.toISOString() + "' and start le '" + to.toISOString() + "' and end ne null");
  }
  getMyTimesBetweenRange(from, to) {
    return this.httpClient.get(this.config.serverUrl + "/get/times?$filter=start ge '" + from.toISOString() + "' and start le '" + to.toISOString() + "' and user__id eq '" + this.userSettingsStore.getValue().id + "' and end ne null");
  }
  getTeamTimesBetweenRange(from, to) {
    return this.httpClient.get(this.config.serverUrl + "/get/times?$filter=start ge '" + from.toISOString() + "' and start le '" + to.toISOString() + "' and end ne null");
  }
  getTeamTimesFilteredBetweenRange(filter) {
    let filterString = "start ge '" + filter.from.toISOString() + "' and start le '" + filter.to.toISOString() + "' ";
    if (filter.userIdsFilter?.length > 0) {
      filterString += ' and (';
      for (let index = 0; index < filter.userIdsFilter.length; index++) {
        const element = filter.userIdsFilter[index];
        filterString += " user__id eq '" + element + "'";
        if (index != filter.userIdsFilter.length - 1) filterString += ' or';
      }
      filterString += ')';
    }
    if (filter.projectIdsFilter?.length > 0) {
      filterString += ' and (';
      for (let index = 0; index < filter.projectIdsFilter.length; index++) {
        const element = filter.projectIdsFilter[index];
        filterString += " project__id eq '" + element + "'";
        if (index != filter.projectIdsFilter.length - 1) filterString += ' or';
      }
      filterString += ')';
    }
    if (filter.clientIdsFilter?.length > 0) {
      filterString += ' and (';
      for (let index = 0; index < filter.clientIdsFilter.length; index++) {
        const element = filter.clientIdsFilter[index];
        filterString += " client__id eq '" + element + "'";
        if (index != filter.clientIdsFilter.length - 1) filterString += ' or';
      }
      filterString += ')';
    }
    if (filter.taskIdsFilter?.length > 0) {
      filterString += ' and (';
      for (let index = 0; index < filter.taskIdsFilter.length; index++) {
        const element = filter.taskIdsFilter[index];
        filterString += " task__id eq '" + element + "'";
        if (index != filter.taskIdsFilter.length - 1) filterString += ' or';
      }
      filterString += ')';
    }
    return this.httpClient.post(this.config.serverUrl + '/get/times', {
      $filter: filterString + ' and end ne null'
    }).pipe(map(response => {
      if (filter.tagIdsFilter?.length > 0) {
        response = response.filter(x => x.tags?.find(y => filter.tagIdsFilter.find(z => z == y.id)));
      }
      if (filter.project_tagIdsFilter?.length > 0) {
        response = response.filter(x => x.project.tags?.find(y => filter.project_tagIdsFilter.find(z => z == y.id)));
      }
      return response;
    }));
  }
  getTeamActivities() {
    var d = new Date();
    d.setHours(23, 59, 59, 999);
    d.setDate(d.getDate());
    return this.httpClient.get(this.config.serverUrl + "/get/times?$filter=start le '" + d.toISOString() + "'&$orderby=start desc&$top=20");
  }
  getById(id) {
    return this.httpClient.get(this.config.serverUrl + '/get/times/' + id).pipe(map(response => {
      return response[0];
    }));
  }
  downloadExcelReport(filter, onUpdate) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.config.serverUrl + '/Create_Excel_Report', filter, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events'
      }).subscribe(e => {
        if (e.type === HttpEventType.DownloadProgress) {
          onUpdate?.(e.loaded, e.total);
        } else if (e.type === HttpEventType.Response) {
          onUpdate?.(e.body.size, e.body.size);
          resolve(e.body);
        }
      }, reject);
    });
  }
  downloadCsvReport(filter, onUpdate) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.config.serverUrl + '/Create_CSV_Report', filter, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events'
      }).subscribe(e => {
        if (e.type === HttpEventType.DownloadProgress) {
          onUpdate?.(e.loaded, e.total);
        } else if (e.type === HttpEventType.Response) {
          onUpdate?.(e.body.size, e.body.size);
          resolve(e.body);
        }
      }, reject);
    });
  }
  static {
    this.ɵfac = function TimesService_Factory(t) {
      return new (t || TimesService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(UserSettingsStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TimesService,
      factory: TimesService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TimesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: UserSettingsStore
  }], null);
})();
let WorkspacesQuery = class WorkspacesQuery extends QueryEntity {
  constructor(store) {
    super(store);
    this.store = store;
  }
  static {
    this.ɵfac = function WorkspacesQuery_Factory(t) {
      return new (t || WorkspacesQuery)(i0.ɵɵinject(WorkspacesStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WorkspacesQuery,
      factory: WorkspacesQuery.ɵfac,
      providedIn: "root"
    });
  }
};
WorkspacesQuery = __decorate([QueryConfig({
  sortBy: "name",
  sortByOrder: Order.ASC
})], WorkspacesQuery);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WorkspacesQuery, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: WorkspacesStore
  }], null);
})();
const log$1 = new Logger('WorkspacesService');
class WorkspacesService {
  constructor(config, httpClient, userSettingsStore, workspaceStore, workspacesQuery) {
    this.config = config;
    this.httpClient = httpClient;
    this.userSettingsStore = userSettingsStore;
    this.workspaceStore = workspaceStore;
    this.workspacesQuery = workspacesQuery;
  }
  get adapter() {
    return this.httpClient;
  }
  get() {
    return this.httpClient.get(this.config.serverUrl + '/get/workspaces').pipe(tap(workspaces => {
      this.workspaceStore.set(workspaces);
    }, error => this.workspaceStore.setError(error)));
  }
  getById(id) {
    return this.httpClient.get(this.config.serverUrl + '/get/workspaces', {
      params: {
        $filter: `id eq ${id}`
      }
    }).toPromise().then(w => {
      if (!w || w.length === 0) throw new Error('workspace.not-exist');
      return w[0];
    }).then(w => {
      this.workspaceStore.upsert(w.id, w);
      return w;
    });
  }
  add(newWorkspace) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'add_workspace'
    });
    return this.httpClient.post(this.config.serverUrl + '/add/workspaces', newWorkspace).pipe(tap(workspaces => this.workspaceStore.add(workspaces), error => this.workspaceStore.setError(error)));
  }
  update(workspace) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'update_workspace'
    });
    const user = this.userSettingsStore.getValue();
    return this.httpClient.post(this.config.serverUrl + '/update/workspaces/' + workspace.id, workspace).pipe(tap(ws => {
      this.workspaceStore.update(ws.id, ws);
      if (user?.workspace && ws.id === user.workspace.id) this.userSettingsStore.update(state => {
        state.workspace = Object.assign({}, state.workspace, ws);
      });
    }, error => this.workspaceStore.setError(error)));
  }
  delete(workspace) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'delete_workspace'
    });
    return this.httpClient.delete(this.config.serverUrl + '/delete/workspaces/' + workspace.id).pipe(tap(() => this.workspaceStore.remove(workspace.id), error => this.workspaceStore.setError(error)));
  }
  addGroup(group) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'add_group'
    });
    return this.httpClient.post(this.config.serverUrl + '/add/groups', group);
  }
  updateGroup(group) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'update_group'
    });
    return this.httpClient.post(this.config.serverUrl + '/update/groups/' + group.id, group);
  }
  deleteGroup(groupId) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'delete_group'
    });
    return this.httpClient.delete(this.config.serverUrl + '/delete/groups/' + groupId, {});
  }
  addAlerts(workspaceId, alerts) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'add_alerts'
    });
    let workspace = produce(this.workspacesQuery.getEntity(workspaceId), draft => {
      if (!draft.alerts) draft.alerts = [];
      alerts.forEach(alert => {
        alert.id = guid();
        draft.alerts.push(alert);
      });
    });
    return this.httpClient.post(this.config.serverUrl + '/update/workspaces/' + workspace.id, workspace).pipe(tap(workspace => this.workspaceStore.update(workspace.id, workspace), error => this.workspaceStore.setError(error)));
  }
  updateAlert(workspaceId, alert) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'update_alerts'
    });
    let workspace = produce(this.workspacesQuery.getEntity(workspaceId), draft => {
      if (!draft.alerts) draft.alerts = [];
      let index = draft.alerts.findIndex(x => x.id == alert.id);
      draft.alerts[index] = alert;
    });
    return this.httpClient.post(this.config.serverUrl + '/update/workspaces/' + workspace.id, workspace).pipe(tap(workspace => this.workspaceStore.update(workspace.id, workspace), error => this.workspaceStore.setError(error)));
  }
  deleteAlert(workspaceId, alertId) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'delete_alert'
    });
    let workspace = produce(this.workspacesQuery.getEntity(workspaceId), draft => {
      if (!draft.alerts) draft.alerts = [];
      draft.alerts = draft.alerts.filter(x => x.id != alertId);
    });
    return this.httpClient.post(this.config.serverUrl + '/update/workspaces/' + workspace.id, workspace).pipe(tap(workspace => this.workspaceStore.update(workspace.id, workspace), error => this.workspaceStore.setError(error)));
  }
  addReminders(workspaceId, reminders) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'add_reminders'
    });
    const newReminders = reminders.filter(Boolean).map(x => ({
      ...x,
      id: guid()
    }));
    const workspace = produce(this.workspacesQuery.getEntity(workspaceId), draft => {
      if (!draft.reminders) draft.reminders = [];
      draft.reminders.push(...newReminders);
    });
    return this.httpClient.post(this.config.serverUrl + '/update/workspaces/' + workspace.id, workspace).pipe(tap(newWorkspace => this.workspaceStore.update(newWorkspace.id, newWorkspace), error => this.workspaceStore.setError(error)), map(x => x.reminders?.filter(r => newReminders.findIndex(n => n.id === r.id) !== -1)));
  }
  updateReminders(workspaceId, reminder) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'update_reminders'
    });
    const workspace = produce(this.workspacesQuery.getEntity(workspaceId), draft => {
      if (!draft.reminders) draft.reminders = [];
      const index = draft.reminders.findIndex(x => x.id === reminder.id);
      draft.reminders[index] = reminder;
    });
    return this.httpClient.post(this.config.serverUrl + '/update/workspaces/' + workspace.id, workspace).pipe(tap(newWorkspace => this.workspaceStore.update(newWorkspace.id, newWorkspace), error => this.workspaceStore.setError(error)), map(x => x.reminders?.find(r => reminder.id === r.id)));
  }
  deleteReminder(workspaceId, reminderId) {
    window.dataLayer.push({
      event: 'API_Events',
      data: 'delete_alert'
    });
    let reminder;
    const workspace = produce(this.workspacesQuery.getEntity(workspaceId), draft => {
      if (!draft.reminders) draft.reminders = [];else reminder = draft.reminders.find(x => x.id === reminderId);
      if (reminder) {
        reminder = {
          ...reminder
        }; // clone object
        draft.reminders = draft.reminders.filter(x => x.id != reminderId);
      }
    });
    return this.httpClient.post(this.config.serverUrl + '/update/workspaces/' + workspace.id, workspace).pipe(tap(newWorkspace => this.workspaceStore.update(newWorkspace.id, newWorkspace), error => this.workspaceStore.setError(error)), map(() => reminder));
  }
  static {
    this.ɵfac = function WorkspacesService_Factory(t) {
      return new (t || WorkspacesService)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(UserSettingsStore), i0.ɵɵinject(WorkspacesStore), i0.ɵɵinject(WorkspacesQuery));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WorkspacesService,
      factory: WorkspacesService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WorkspacesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: i1.HttpClient
  }, {
    type: UserSettingsStore
  }, {
    type: WorkspacesStore
  }, {
    type: WorkspacesQuery
  }], null);
})();
let ApplicationSettingsStore = class ApplicationSettingsStore extends Store {
  constructor() {
    super({});
  }
  static {
    this.ɵfac = function ApplicationSettingsStore_Factory(t) {
      return new (t || ApplicationSettingsStore)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ApplicationSettingsStore,
      factory: ApplicationSettingsStore.ɵfac,
      providedIn: "root"
    });
  }
};
ApplicationSettingsStore = __decorate([StoreConfig({
  name: "applicationSettings",
  producerFn: produce
})], ApplicationSettingsStore);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApplicationSettingsStore, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [], null);
})();
class ApplicationSettingsQuery extends Query {
  constructor(store) {
    super(store);
    this.store = store;
  }
  updateByKey(key, value) {
    return this.store.update(state => {
      state = set(cloneDeep(state), key, value);
      return state;
    });
  }
  static {
    this.ɵfac = function ApplicationSettingsQuery_Factory(t) {
      return new (t || ApplicationSettingsQuery)(i0.ɵɵinject(ApplicationSettingsStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ApplicationSettingsQuery,
      factory: ApplicationSettingsQuery.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApplicationSettingsQuery, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: ApplicationSettingsStore
  }], null);
})();
let ClientsQuery = class ClientsQuery extends QueryEntity {
  constructor(store) {
    super(store);
    this.store = store;
  }
  static {
    this.ɵfac = function ClientsQuery_Factory(t) {
      return new (t || ClientsQuery)(i0.ɵɵinject(ClientsStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ClientsQuery,
      factory: ClientsQuery.ɵfac,
      providedIn: "root"
    });
  }
};
ClientsQuery = __decorate([QueryConfig({
  sortBy: "name",
  sortByOrder: Order.ASC
})], ClientsQuery);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClientsQuery, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: ClientsStore
  }], null);
})();
let ComegoQuery = class ComegoQuery extends QueryEntity {
  constructor(store) {
    super(store);
    this.store = store;
  }
  clear() {
    this.store.remove();
  }
  selectAllFromCurrentUser(options) {
    const userSettingsStore = inject(UserSettingsStore);
    const user = userSettingsStore?.getValue();
    if (!user || !user.id) return this.selectAll();
    const hasArguments = !!options;
    const filterBy = options?.filterBy;
    return this.selectAll({
      ...options,
      filterBy: hasArguments && typeof options.filterBy === "function" ? x => filterBy(x) && x.user?.id === user.id : x => x.user?.id === user.id
    });
  }
  instance() {
    return this.store;
  }
  static {
    this.ɵfac = function ComegoQuery_Factory(t) {
      return new (t || ComegoQuery)(i0.ɵɵinject(ComegoStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ComegoQuery,
      factory: ComegoQuery.ɵfac,
      providedIn: 'root'
    });
  }
};
ComegoQuery = __decorate([QueryConfig({
  sortBy: 'start',
  sortByOrder: Order.DESC
})], ComegoQuery);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComegoQuery, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ComegoStore
  }], null);
})();
let FeedQuery = class FeedQuery extends QueryEntity {
  constructor(store) {
    super(store);
    this.store = store;
  }
  getHasMore() {
    return this.getValue().nextDate != null;
  }
  getNextDate() {
    return this.getValue().nextDate;
  }
  static {
    this.ɵfac = function FeedQuery_Factory(t) {
      return new (t || FeedQuery)(i0.ɵɵinject(FeedStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FeedQuery,
      factory: FeedQuery.ɵfac,
      providedIn: 'root'
    });
  }
};
FeedQuery = __decorate([QueryConfig({
  sortBy: 'end',
  sortByOrder: Order.DESC
})], FeedQuery);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FeedQuery, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: FeedStore
  }], null);
})();
let TagsQuery = class TagsQuery extends QueryEntity {
  constructor(store) {
    super(store);
    this.store = store;
  }
  static {
    this.ɵfac = function TagsQuery_Factory(t) {
      return new (t || TagsQuery)(i0.ɵɵinject(TagsStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TagsQuery,
      factory: TagsQuery.ɵfac,
      providedIn: "root"
    });
  }
};
TagsQuery = __decorate([QueryConfig({
  sortBy: "name",
  sortByOrder: Order.ASC
})], TagsQuery);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TagsQuery, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: TagsStore
  }], null);
})();
let TasksQuery = class TasksQuery extends QueryEntity {
  constructor(store) {
    super(store);
    this.store = store;
  }
  static {
    this.ɵfac = function TasksQuery_Factory(t) {
      return new (t || TasksQuery)(i0.ɵɵinject(TasksStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TasksQuery,
      factory: TasksQuery.ɵfac,
      providedIn: "root"
    });
  }
};
TasksQuery = __decorate([QueryConfig({
  sortBy: "name",
  sortByOrder: Order.ASC
})], TasksQuery);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TasksQuery, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: TasksStore
  }], null);
})();
const log = new Logger("Error");
class MonitoringErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }
  handleError(error) {
    if (!error) return;
    if (window.location.origin.indexOf("localhost") == -1) log.error(error); // Manually log exception
    super.handleError(error);
  }
  static {
    this.ɵfac = function MonitoringErrorHandler_Factory(t) {
      return new (t || MonitoringErrorHandler)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MonitoringErrorHandler,
      factory: MonitoringErrorHandler.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MonitoringErrorHandler, [{
    type: Injectable
  }], () => [], null);
})();
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal
function loggerCallback(logLevel, message) {
  if (logLevel == LogLevel$2.Error) console.error(message);
}
function MSALGuardConfigFactory() {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: initialScopes
    }
  };
}
function MSALInstanceFactory(config) {
  return new PublicClientApplication({
    auth: {
      clientId: config.clientId,
      redirectUri: config.redirectUri ? config.redirectUri : location.origin,
      postLogoutRedirectUri: window['teams'] ? window.location.origin + '/teams-auth-iframe.html' : config.postLogoutRedirectUri ? config.postLogoutRedirectUri : window.location.origin
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel$2.Error,
        piiLoggingEnabled: false
      }
    }
  });
}
function MSALInterceptorConfigFactory(config) {
  const protectedResourceMap = new Map();
  protectedResourceMap.set('https://graph.microsoft.com/beta/me/outlook/tasks', [Scope.OutlookTasks]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me/planner/tasks', [Scope.Groups]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me/calendarview', [Scope.Calendar]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me/calendars', [Scope.Calendar]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me/drive/recent', [Scope.RecentFiles]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me/people', [Scope.People]);
  protectedResourceMap.set('https://graph.microsoft.com/beta/groups', [Scope.Groups]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/users', [Scope.Users]);
  protectedResourceMap.set('https://graph.microsoft.com/beta/me/insights/used', [Scope.Sites]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [Scope.Me]);
  protectedResourceMap.set(config.serverUrl, [config.apiAccessUrl]);
  protectedResourceMap.set('https://app.vssps.visualstudio.com', [devOpsScope]);
  protectedResourceMap.set('https://dev.azure.com', [devOpsScope]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
class HttpTimeghostInterceptor {
  constructor(config, userSettingsStore, authService) {
    this.config = config;
    this.userSettingsStore = userSettingsStore;
    this.authService = authService;
  }
  intercept(req, next) {
    if (!req.url.startsWith(this.config.serverUrl)) return next.handle(req);
    const userSettings = this.userSettingsStore.getValue();
    if (userSettings && userSettings.id) {
      let headers = req.headers.append("workspace-id", userSettings.workspace.id);
      headers = headers.append("principal-id", userSettings.id);
      req = req.clone({
        headers: headers
      });
      return from(this.handle(req, next));
    } else {
      return from(this.handle(req, next));
    }
  }
  async handle(req, next) {
    let i = await this.authService.acquireTokenSilent({
      scopes: [Scope.Me]
    }).toPromise();
    let headers = req.headers.append("graph-token", i.accessToken);
    req = req.clone({
      headers: headers
    });
    // Important: Note the .toPromise()
    return next.handle(req).toPromise();
  }
  static {
    this.ɵfac = function HttpTimeghostInterceptor_Factory(t) {
      return new (t || HttpTimeghostInterceptor)(i0.ɵɵinject(LibConfig), i0.ɵɵinject(UserSettingsStore), i0.ɵɵinject(i2.MsalService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HttpTimeghostInterceptor,
      factory: HttpTimeghostInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpTimeghostInterceptor, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LibConfig]
    }]
  }, {
    type: UserSettingsStore
  }, {
    type: i2.MsalService
  }], null);
})();
class TimeghostApiModule {
  static forRoot(config) {
    return {
      ngModule: TimeghostApiModule,
      providers: [{
        provide: LibConfig,
        useValue: config
      }, {
        provide: ErrorHandler,
        useClass: MonitoringErrorHandler
      }, {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpTimeghostInterceptor,
        multi: true
      }, {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      }, {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory,
        deps: [LibConfig]
      }, {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
      }, {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory,
        deps: [LibConfig]
      }, MsalService, MsalGuard, MsalBroadcastService]
    };
  }
  static {
    this.ɵfac = function TimeghostApiModule_Factory(t) {
      return new (t || TimeghostApiModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TimeghostApiModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TimeghostApiModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [],
      exports: []
    }]
  }], null, null);
})();

/*
 * Public API Surface of timeghost-api
 */
//Module
// Services

/**
 * Generated bundle index. Do not edit.
 */

export { AlertReceiver, AlertTypes, ApplicationSettingsQuery, ApplicationSettingsStore, BaseService, ChargebeeService, ClientsQuery, ClientsService, ComegoQuery, ComegoService, ComegoStore, ExternalScope, FeedQuery, FeedService, FreshdeskService, GroupDefaults, LibConfig, LogLevel, Logger, MyTimesQuery, MyTimesService, NotifyService, PaddleService, PermissionRole$1 as ProjectPermissionRole, ProjectsQuery, ProjectsService, ReminderByTheEndOfThe, Scope, SearchService, TagType, TagsQuery, TagsService, TasksQuery, TasksService, TimeghostApiModule, TimesService, UserImagesQuery, UserImagesStore, UserService, UserSettingsQuery, WhoCanAddTasks, PermissionRole as WorkspacePermissionRole, WorkspacesQuery, WorkspacesService, initialScopes, persistStateSettings };
